import { ControlledInput } from "@/UI/Input";
import { LoadingSpinner } from "@/UI/Loading";
import { Modal } from "@/UI/Modal";
import { ControlledMultiSelect } from "@/UI/MultiSelect";
import { ControlledTextArea } from "@/UI/TextArea";
import { CreatePriceBookItemInput } from "@/lib/graphql/graphql";
import { QUERY_KEYS } from "@/lib/react-query/constants";
import { queryClient } from "@/lib/react-query/general";
import { useCreatePricebookItem } from "@/lib/react-query/mutationHooks/useCreatePricebookItem";
import { useBranches } from "@/lib/react-query/queryHooks/useBranches";
import { useDivisions } from "@/lib/react-query/queryHooks/useDivisions";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { FieldErrors, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { z } from "zod";

const pricebookDetailSchema = z.object({
  name: z.string().min(1, { message: "Please provide a Form Name." }),
  description: z
    .string()
    .min(1, { message: "Please provide an item description." }),
  divisionIds: z.array(z.object({ value: z.string(), label: z.string() })),
  branchIds: z.array(z.object({ value: z.string(), label: z.string() })),
  rate: z.number().min(1, { message: "Please provide a positive number" }),
  unit: z.string().min(1, { message: "Please provide a unit" }),
  category: z.string().min(1, { message: "Please provide a category" }),
});

type PricebookDetailSchema = z.infer<typeof pricebookDetailSchema>;

const pricebookDetailDefaultValues = {
  name: "My Pricebook item",
  description: "Description for My Pricebook item",
  rate: 100,
  unit: "",
  category: "",
};

interface PricebookItemModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PricebookItemModal: React.FC<PricebookItemModalProps> = ({
  open,
  setOpen,
}) => {
  const { data: branches } = useBranches({
    options: { staleTime: 5 * 1000 * 60 },
  });
  const { data: divisions } = useDivisions({
    options: { staleTime: 5 * 1000 * 60 },
  });
  const branchOptions = branches?.data?.map((branchOption) => ({
    label: branchOption.name,
    value: branchOption.id,
  }));
  const divisionOptions = divisions?.data?.map((divisionOption) => ({
    label: divisionOption.divisionName,
    value: divisionOption.id,
  }));
  const { control, handleSubmit, setValue, formState, reset } =
    useForm<PricebookDetailSchema>({
      defaultValues: pricebookDetailDefaultValues,
      resolver: zodResolver(pricebookDetailSchema),
    });
  const createPricebookItem = useCreatePricebookItem();
  const onSubmit = (data: PricebookDetailSchema) => {
    const createPricebookItemBody: CreatePriceBookItemInput = {
      name: data.name,
      rate: data.rate,
      unit: data.unit,
      category: data.category,
      description: data.description,
      branchIds: data.branchIds.map((branch) => branch.value),
      divisionIds: data.divisionIds.map((division) => division.value),
    };
    createPricebookItem.mutate(createPricebookItemBody, {
      onSuccess: async () => {
        toast.success("Pricebook Item Created");
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.PRICEBOOK_ITEMS],
        });
      },
      onError: () => {
        toast.error("Error creating pricebook item");
      },
    });
    setOpen(false);
  };

  const onError = (error: FieldErrors<PricebookDetailSchema>) =>
    console.error(error);
  return (
    <Modal
      open={open}
      innerContainerClasses="!w-[500px] text-2xl font-semibold"
      onCancel={() => {
        setOpen(false);
        reset();
      }}
      disableConfirm={!formState.isDirty}
      confirmButtonText="Confirm"
      onConfirm={handleSubmit(onSubmit, onError)}
    >
      {createPricebookItem.isPending && (
        <div className="relative z-50 translate-x-[200px] translate-y-[10px]">
          <LoadingSpinner />
        </div>
      )}
      <h1 className="mb-8 text-2xl font-semibold">Add Pricebook Item</h1>
      <div className="my-4 flex max-w-xl space-x-4">
        <ControlledInput
          control={control}
          name="name"
          label="Item Name"
          containerClasses="!w-full"
        />
      </div>
      <div className="my-4 flex  max-w-xl space-x-4">
        <ControlledTextArea
          control={control}
          name="description"
          label="Form Description"
          containerClassNames="w-full"
          textareaStyles="h-32"
        />
      </div>
      <div className="my-4 flex max-w-xl space-x-4">
        <ControlledInput
          control={control}
          name="category"
          label="Item Category"
          containerClasses="!w-full"
        />
      </div>

      <div className="my-4 flex w-full max-w-xl justify-between space-x-4">
        <div className="w-64">
          <ControlledInput
            inputClasses="pl-6"
            startAdornment={<span className="mt-4 text-sm">$</span>}
            type="number"
            control={control}
            name="rate"
            label="Rate"
            onChange={(e) => setValue("rate", Number(e.target.value))}
          />
        </div>

        <span className="mt-auto h-full -translate-y-2 place-self-center text-sm">
          /
        </span>
        <div className="w-64">
          <ControlledInput control={control} name="unit" label="Unit" />
        </div>
      </div>
      <div className="my-4 flex w-full max-w-xl justify-between space-x-4">
        <div className="w-64 text-sm">
          <ControlledMultiSelect<PricebookDetailSchema>
            control={control}
            name="divisionIds"
            label="Divisions"
            options={divisionOptions}
          />
        </div>
        <div className="w-64 text-sm">
          <ControlledMultiSelect<PricebookDetailSchema>
            control={control}
            name="branchIds"
            label="Branches"
            options={branchOptions}
          />
        </div>
      </div>
    </Modal>
  );
};
