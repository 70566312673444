/* eslint-disable @typescript-eslint/no-explicit-any */

import { FC } from "react";
import {
  SidebarCalendarIcon,
  NotebookIcon,
  QuadrilateralsIcon,
  UserIcon,
  RibbiotIcon,
  MapIcon,
  SettingsIcon,
  QuoteIcon,
  AdminRibbiotIcon,
} from "../assets";

export type Nav = {
  name: string;
  icon: FC<any>;
  current: boolean;
  href: string;
  expandedRoutes: string[];
};

export const extraSidebarRoutes = [
  "/assets/asset-detail",
  "/assets/asset-documents",
  "/assets/asset-images",
  "/assets/browser",
  "/assets/overview",
  "/assets/search",
  "/jobs/dashboard",
  "/jobs/job-detail",
  "/jobs/job-documents",
  "/settings",
  "/settings/form-manager",
  "/settings/pricebook",
  "/admin",
  "/admin/branch/create",
  "/admin/division/create",
  "/admin/forms",
  "/admin/forms/create",
  "/admin/forms/edit-csv-config",
  "/admin/forms/edit-dynamic-document",
  "/admin/forms/edit-ios-layout",
  "/admin/forms/edit-android-layout",
  "/admin/forms/edit-raw-form",
  "/admin/forms/edit-web-layout",
  "/admin/icons",
  "/admin/location",
  "/admin/users",
  "/admin/users/create",
  "/admin/users/user-detail",
  "/users/create",
  "/users/user-detail",
];

export const navBase = [
  {
    name: "Home",
    href: "",
    icon: RibbiotIcon,
    current: false,
    expandedRoutes: [],
  },
];

export const webAssetMapNavigation = [
  {
    name: "Map",
    icon: MapIcon,
    current: false,
    href: "map",
    expandedRoutes: [
      "/assets/browser",
      "/assets/search",
      "/assets/overview",
      "/assets/asset-images",
      "/assets/asset-documents",
    ],
  },
];

export const webScheduleNavigation = [
  {
    name: "Schedule",
    icon: SidebarCalendarIcon,
    current: false,
    href: "schedule",

    expandedRoutes: [
      "/jobs/job-detail",
      "/jobs/dashboard",
      "/jobs/job-documents",
    ],
  },
];

export const webAssetCrudNavigation = [
  {
    name: "Assets",
    icon: QuadrilateralsIcon,
    current: false,
    href: "assets/browser",

    expandedRoutes: [
      "/assets/browser",
      "/assets/search",
      "/assets/overview",
      "/assets/asset-detail",
      "/assets/asset-images",
      "/assets/asset-documents",
    ],
  },
];

export const webUserCrudNavigation = [
  {
    name: "Users",
    icon: UserIcon,
    current: false,
    href: "users",
    expandedRoutes: ["/users/create", "/users/user-detail"],
  },
];

export const timecardAdminNavigation = [
  {
    name: "Documents",
    icon: NotebookIcon,
    current: false,
    href: "documents",
    expandedRoutes: [],
  },
];

export const webQuotingNavigation = [
  {
    name: "Quoting",
    icon: QuoteIcon,
    current: false,
    href: "quoting",
    expandedRoutes: [],
  },
];

export const webSettingsNavigation = [
  {
    name: "Settings",
    current: false,
    icon: SettingsIcon,
    href: "settings",
    expandedRoutes: [
      "/settings",
      "/settings/form-manager",
      "/settings/pricebook",
    ],
  },
];

export const webAdminNavigation = [
  {
    name: "Admin",
    current: false,
    icon: AdminRibbiotIcon,
    href: "admin",
    expandedRoutes: [
      "/admin",
      "/admin/branch/create",
      "/admin/division/create",
      "/admin/forms",
      "/admin/forms/create",
      "/admin/forms/edit-csv-config",
      "/admin/forms/edit-dynamic-document",
      "/admin/forms/edit-ios-layout",
      "/admin/forms/edit-android-layout",
      "/admin/forms/edit-raw-form",
      "/admin/forms/edit-web-layout",
      "/admin/icons",
      "/admin/location",
      "/admin/pricebook",
      "/admin/pricebook/create",
      "/admin/users",
      "/admin/users/create",
      "/admin/users/user-detail",
    ],
  },
];
