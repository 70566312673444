import { z } from "zod";
import { FC, useEffect } from "react";
import { Division } from "@/types/users/general";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ControlledInput } from "@/UI/Input/ControlledInput";
import { Button } from "@/UI/Button";
import { divisionFormDefaultValues } from "./helpers";
import { classNames } from "@/utils/helpers/classNames";
import { useEditDivision } from "@/lib/react-query/mutationHooks/useEditDivision";
import { useCreateDivision } from "@/lib/react-query/mutationHooks/useCreateDivision";
import { toast } from "react-toastify";

type AdminDivisionFormProps = {
  divisionData: Division | undefined;
  edit: boolean;
};

const handleAdminDivisionFormSchema = z.object({
  id: z.string().optional(),
  divisionName: z.string().min(1, { message: "Division name is required" }),
});

export type AdminDivisionFormSchema = z.infer<
  typeof handleAdminDivisionFormSchema
>;
const AdminDivisionForm: FC<AdminDivisionFormProps> = ({
  divisionData,
  edit,
}) => {
  const { handleSubmit, formState, reset, setError, control } =
    useForm<AdminDivisionFormSchema>({
      defaultValues: {
        ...divisionFormDefaultValues,
      },
      resolver: zodResolver(handleAdminDivisionFormSchema),
    });

  const editDivisionMutation = useEditDivision();
  const createDivision = useCreateDivision();

  const onSubmit: SubmitHandler<AdminDivisionFormSchema> = async (data) => {
    if (data) {
      if (!data.divisionName) {
        setError("divisionName", {
          type: "manual",
          message: "Division name is required",
        });
        return;
      }

      if (edit && divisionData) {
        // call edit mutation
        editDivisionMutation.mutate({
          body: {
            ...data,
          },
        });
      } else {
        createDivision.mutate(
          {
            body: {
              ...data,
            },
          },
          {
            onSuccess: () => {
              toast.success("Branch created");
            },
            onError: () => {
              toast.error("Error creating branch");
            },
          }
        );
      }
    }
  };

  useEffect(() => {
    if (divisionData) {
      reset({
        id: divisionData.id || "",
        divisionName: divisionData.divisionName || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divisionData]);

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit, (errors) =>
          console.error("errors", errors)
        )}
      >
        <div className="auto-col-auto  grid grid-flow-col gap-4">
          <div className="w-100">
            <ControlledInput
              control={control}
              name="id"
              label="Division ID"
              disabled={true}
            />
          </div>
          <div className="w-100">
            <ControlledInput
              control={control}
              name="divisionName"
              label="Division Name"
            />
          </div>
        </div>

        <div
          className={classNames(
            "mt-6 flex flex-col justify-end gap-5 lg:flex-row"
          )}
        >
          <Button
            label="Save Division"
            type="submit"
            variant="primary"
            disabled={!formState.isDirty}
            className="rounded-md"
          />
        </div>
      </form>
    </>
  );
};

export { AdminDivisionForm };
