import { Button } from "@/UI/Button";
import { Divider } from "@/UI/Divider";
import { Input } from "@/UI/Input";
import { LoadingSpinner } from "@/UI/Loading";
import { StickyHeader } from "@/UI/StickyHeader";
import { CircledXIcon } from "@/assets";
import { useFormTemplateHomePage } from "@/lib/react-query/queryHooks/useFormTemplateHomePage";
import { useFormTemplates } from "@/lib/react-query/queryHooks/useFormTemplates";
import * as React from "react";

export const SettingsFormManagerPage: React.FC = () => {
  const { data: homePageData, isLoading: formTemplateHomePageLoading } =
    useFormTemplateHomePage();
  const { data: formTemplatesQueryData, isLoading: formTemplatesLoading } =
    useFormTemplates();
  const quickLinksData = homePageData?.data?.formTemplateHomePage?.quickLinks;
  const templateTypeOrderData =
    homePageData?.data?.formTemplateHomePage?.templateTypeOrder;
  const formTemplatesData = formTemplatesQueryData?.data?.formTemplates;
  const pageLoading = formTemplateHomePageLoading || formTemplatesLoading;
  return (
    <div className="flex  w-[100%] flex-col py-14">
      {pageLoading && <LoadingSpinner />}
      <div className="flex justify-center">
        <section className="max-w-[800px] basis-3/4 rounded-lg">
          <div className="rounded-lg border bg-white">
            <StickyHeader id="form-manager-form-header">
              <div className="ml-[100px] flex w-full px-14">
                <div className="flex w-full justify-center">
                  <div className="flex max-w-[800px] basis-3/4 content-center justify-between">
                    <h1 className="my-auto text-3xl font-semibold">
                      Form Manager
                    </h1>
                    <div className="my-auto space-x-4 ">
                      <Button
                        onClick={() => console.log("save")}
                        label="Save Changes"
                        variant="primary"
                        className="rounded-md"
                        loading={pageLoading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </StickyHeader>

            <header className="flex justify-between rounded-t-lg bg-theme-white-secondary p-6">
              <h1 className="text-4xl font-semibold">Form Manager</h1>
              <Button
                onClick={() => console.log("save")}
                label="Save Changes"
                variant="primary"
                className="rounded-md"
                loading={pageLoading}
              />
            </header>
            <Divider additionalClasses="border-gray-300" />
            <div className="p-4">
              <h2 className="my-2 text-xl font-semibold">Quick Link Order</h2>
              <Divider />
              {quickLinksData?.map((quickLink) => (
                <div key={quickLink.createdAt} className="my-4 flex space-x-4">
                  <Input
                    label="Form Name"
                    value={quickLink.formId ?? ""}
                    containerClasses="w-full"
                  />
                  <Input
                    label="Divisions"
                    value={quickLink.divisionIds?.join(", ")}
                    containerClasses="w-full"
                  />
                  <button className="mt-4">
                    <CircledXIcon />
                  </button>
                </div>
              ))}
              <Button
                label="Add Quick Link"
                variant="primary"
                className="my-4 rounded-md"
              />
              <h2 className="mb-2 mt-6 text-xl font-semibold">Type Order</h2>
              <Divider />
              {templateTypeOrderData?.map((typeOrder, index) => (
                <div
                  key={typeOrder?.formType.slug ?? index}
                  className="my-4 flex space-x-4"
                >
                  <Input
                    value={typeOrder.formType.slug ?? ""}
                    containerClasses="w-full my-4"
                    disabled
                  />
                </div>
              ))}
              <h2 className="mb-2 mt-6 text-xl font-semibold">Form list</h2>
              <Divider />
              {formTemplatesData?.map((formTemplate) => (
                <div key={formTemplate?.id} className="my-4 flex space-x-4">
                  <Input
                    value={formTemplate?.name}
                    containerClasses="w-full my-4"
                  />
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
