import { AMPLITUDE_EVENTS } from "@/utils/helpers/amplitudeEvents";
import { BackArrow } from "@/assets";
import { classNames } from "@/utils/helpers/classNames";
import { FC, SetStateAction } from "react";
import { handleExpandedNavigation } from "@/utils/helpers/handleExpandedNavigation";
import { HandleNavigationParams } from "@/utils/helpers/handleNavigation";
import { QUERY_KEYS } from "@/lib/react-query/constants";
import { queryClient } from "@/lib/react-query/general";
import { useLocation, useNavigate } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";

type Props = {
  setDangerModalOpen: (value: SetStateAction<boolean>) => void;
  setNavigationParams: (
    value: SetStateAction<HandleNavigationParams | undefined>
  ) => void;
  showUnsavedDataWarning: boolean;
};

const JobsExpandedContent: FC<Props> = ({
  setDangerModalOpen,
  setNavigationParams,
  showUnsavedDataWarning,
}) => {
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const jobId = searchParams.get("jobId") ?? "";

  return (
    <>
      <button
        className={classNames(
          "flex h-10 w-[149px] items-center justify-start gap-x-2 rounded-md pl-3"
        )}
        onClick={(event) => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.JOB, { state }],
          });

          handleExpandedNavigation({
            navParams: {
              event,
              href: "schedule",
              navigate,
              pathname,
            },
            setDangerModalOpen,
            setNavigationParams,
            showUnsavedDataWarning,
          });
        }}
      >
        <BackArrow />

        <span className="text-xs font-medium text-palette-secondaryBlack">
          All Jobs
        </span>
      </button>

      <button
        className={classNames(
          "flex h-10 w-[149px] items-center justify-start rounded-md pl-3",
          pathname === "/jobs/dashboard" ? "bg-palette-secondaryGreen" : ""
        )}
        onClick={(event) => {
          if (pathname !== "/jobs/dashboard") {
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEYS.JOB, { state }],
            });

            handleExpandedNavigation({
              navParams: {
                event,
                href: `jobs/dashboard?jobId=${jobId}`,
                navigate,
                pathname,
                skipValidation: true,
                state,
              },
              setDangerModalOpen,
              setNavigationParams,
              showUnsavedDataWarning,
            });
          }
        }}
      >
        <span className="text-sm font-medium">Overview</span>
      </button>

      <button
        className={classNames(
          "flex h-10 w-[149px] items-center justify-start rounded-md pl-3",
          pathname === "/jobs/job-detail" ? "bg-palette-secondaryGreen" : ""
        )}
        onClick={(event) => {
          if (pathname !== "/jobs/job-details") {
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEYS.JOB, { state }],
            });
            amplitude.track(AMPLITUDE_EVENTS.JOB_DETAILS_ENTERED);

            handleExpandedNavigation({
              navParams: {
                event,
                href: `jobs/job-detail?jobId=${jobId}`,
                navigate,
                pathname,
                skipValidation: true,
                state,
              },
              setDangerModalOpen,
              setNavigationParams,
              showUnsavedDataWarning,
            });
          }
        }}
      >
        <span className="text-sm font-medium">Job Details</span>
      </button>

      <button
        className={classNames(
          "flex h-10 w-[149px] items-center justify-start rounded-md pl-3",
          pathname === "/jobs/job-documents" ? "bg-palette-secondaryGreen" : ""
        )}
        onClick={(event) => {
          if (pathname !== "/jobs/job-documents") {
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEYS.JOB, { state }],
            });
            amplitude.track(AMPLITUDE_EVENTS.JOB_DETAILS_ENTERED);

            handleExpandedNavigation({
              navParams: {
                event,
                href: `jobs/job-documents?jobId=${jobId}`,
                navigate,
                pathname,
                skipValidation: true,
                state,
              },
              setDangerModalOpen,
              setNavigationParams,
              showUnsavedDataWarning,
            });
          }
        }}
      >
        <span className="text-sm font-medium">Job Documents</span>
      </button>
    </>
  );
};

export { JobsExpandedContent };
