import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { DefinedInitialDataOptions, useQuery } from "@tanstack/react-query";
import { FormTemplateHomePageQuery } from "@/lib/graphql/graphql";
import { graphql } from "@/lib/graphql";

interface UseFormTemplateHomePageQueryParams {
  options?: Partial<
    DefinedInitialDataOptions<{ data: FormTemplateHomePageQuery }>
  >;
}

const FormTemplateHomePage = graphql(`
  query FormTemplateHomePage($accountId: ID!) {
    formTemplateHomePage(accountId: $accountId) {
      quickLinks {
        formId
        formType {
          slug
          displayName
          description
          createdAt
          updatedAt
          iconUrl
          bgColor
          iconType
          ordinal
          isGlobal
        }
        displayName
        ordinal
        createdAt
        updatedAt
        accountId
        divisionIds
        branchIds
      }
      templateTypeOrder {
        formIds
        formType {
          slug
          displayName
          description
          createdAt
          updatedAt
          iconUrl
          bgColor
          iconType
          ordinal
          isGlobal
        }
      }
    }
  }
`);

export const useFormTemplateHomePage = (
  params?: UseFormTemplateHomePageQueryParams
) => {
  const { graphqlRequest, accountId } = useAuthContext();
  return useQuery({
    queryKey: [QUERY_KEYS.FORM_TEMPLATE_HOME_PAGE],
    queryFn: async () => graphqlRequest(FormTemplateHomePage, { accountId }),
    ...(params?.options && { ...params.options }),
  });
};
