/* eslint-disable @typescript-eslint/no-explicit-any */

import { assetHistoryColumnsDefinition } from "@/utils/columnDefinitions/assetHistory";
import { compareDesc } from "date-fns";
import { LoadingSpinner } from "@/UI/Loading";
import { Table } from "@/components/Table";
import { useAsset } from "@/lib/react-query/queryHooks/useAsset";
import { useInfiniteAssetHistory } from "@/lib/react-query/queryHooks/useInfiniteAssetHistory";
import { useInfiniteScroll } from "@/utils/hooks/useInfiniteScroll";
import { useParams } from "react-router-dom";

const AdminAssetHistoryPage = () => {
  const { assetid } = useParams();

  const {
    data: assetData,
    isLoading: assetLoading,
    isFetching: assetFetching,
    isRefetching: assetRefetching,
  } = useAsset({ assetId: assetid || "" });

  const {
    data: assetHistoryData,
    hasNextPage,
    fetchNextPage,
    isLoading: assetHistoryLoading,
    isFetching: assetHistoryFetching,
    isFetchingNextPage,
    isRefetching: assetHistoryRefetching,
  } = useInfiniteAssetHistory({
    assetId: assetid || "",
  });

  const historyData = assetHistoryData?.pages
    .flatMap((history) => history.data)
    .sort((a, b) => compareDesc(new Date(a.dateTime), new Date(b.dateTime)));

  useInfiniteScroll({
    fetchNextPage: fetchNextPage,
    hasNextPage: hasNextPage,
    tableData: historyData as any,
  });

  const loadingState =
    assetHistoryLoading ||
    assetHistoryFetching ||
    isFetchingNextPage ||
    assetHistoryRefetching ||
    assetLoading ||
    assetFetching ||
    assetRefetching;

  return (
    <div className="h-screen w-full pt-14">
      <header className="mb-5 flex w-full flex-row justify-between">
        <h1 className="text-4xl font-semibold">Location History</h1>
      </header>

      <div className="h-[75vh] w-full overflow-auto rounded-md bg-white">
        {loadingState && <LoadingSpinner />}
        <Table
          emptyMessage={loadingState ? "" : "No history found."}
          tableColumns={assetHistoryColumnsDefinition({
            formattedAddres: assetData?.asset.address?.formattedAddress,
            radius: assetData?.asset.radius,
          })}
          tableData={historyData || []}
        />
      </div>
    </div>
  );
};
export default AdminAssetHistoryPage;
