import { Button } from "@/UI/Button";
import { useAuthContext } from "@/components/Auth/AuthWrapper";
import { useAccount } from "@/lib/react-query/queryHooks/useAccount";
import { classNames } from "@/utils/helpers/classNames";
import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeftIcon, TableCellsIcon } from "@heroicons/react/24/outline";
import { useEditForm } from "@/lib/react-query/mutationHooks/admin/useEditForm";
import { Alert } from "@/UI/Alert";
import { useAlert } from "@/utils/hooks/useAlert";
import { toast } from "react-toastify";
import { queryClient } from "@/lib/react-query/general";
import { QUERY_KEYS } from "@/lib/react-query/constants";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useSyncFormTemplate } from "@/lib/react-query/mutationHooks/useSyncFormTemplate";
import { useFormTemplate } from "@/lib/react-query/queryHooks/useFormTemplate";
import { useEditFormGql } from "@/lib/react-query/mutationHooks/admin/useEditFormGql";
import { removeNullishValues } from "@/utils/helpers/removeNullishValues";
import { LoadingSpinner } from "@/UI/Loading";

const AdminEditRawFormPage: FC = () => {
  const { webGqlFormTemplatesMigration } = useFlags();
  const { formid } = useParams();
  const navigate = useNavigate();
  const { accountId } = useAuthContext();

  const { data: accountData, isLoading: accountLoading } = useAccount({
    accountId,
    options: { staleTime: 5 * 1000 * 60 },
  });
  const { mutateAsync: syncFormTemplate, isPending: syncFormTemplatePending } =
    useSyncFormTemplate();
  const { data: formTemplate, isLoading: formTemplateLoading } =
    useFormTemplate({
      formTemplateId: formid ?? "",
      options: { staleTime: 5 * 1000 * 60, enabled: !!formid },
    });

  const editFormMutation = useEditForm();
  const { mutateAsync: editFormGqlMutation, isPending: editFormGqlPending } =
    useEditFormGql();

  const getFormTemplate = () => {
    if (webGqlFormTemplatesMigration === "live") {
      return removeNullishValues(formTemplate?.data?.formTemplate);
    }
    return accountData?.data?.forms?.find((form) => form.id === formid);
  };

  const form = useMemo(
    () => getFormTemplate(),
    [formTemplate?.data?.formTemplate, accountData]
  );

  const { alertProps, closeAlert, setAlert } = useAlert();

  const formName =
    form?.fields?.find((field: any) => field?.name === "Form Description")
      ?.text ?? form?.name;
  const [formText, setFormText] = useState<string | undefined>();
  const [isInvalidJSON, setIsInvalidJSON] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const handleSave = () => {
    setAlert({
      body: "This action cannot be undone. Please take a backup of existing form just in case.",
      cancelButtonText: "Cancel",
      confirmButtonText: "Save",
      onCancel: () => {
        closeAlert();
      },
      onConfirm: async () => {
        closeAlert();
        if (!formText || !formid) return;
        if (webGqlFormTemplatesMigration === "live") {
          const updatedSqlForm = JSON.parse(formText);
          delete updatedSqlForm.id;
          delete updatedSqlForm.accountId;
          delete updatedSqlForm.updatedAt;
          delete updatedSqlForm.createdAt;
          await editFormGqlMutation(
            {
              input: updatedSqlForm,
              updateFormTemplateId: formid,
            },
            {
              onSuccess: () => {
                toast.success("Form updated successfully in SQL..");
                queryClient.invalidateQueries({
                  queryKey: [QUERY_KEYS.FORM_TEMPLATE, { formId: formid }],
                });
              },
              onError: () => {
                toast.error("Error updating form.");
              },
            }
          );
          return;
        }
        await editFormMutation.mutateAsync(
          { form: formText },
          {
            onSuccess: () => {
              toast.success("Form updated successfully in Dynamo.");
              queryClient.invalidateQueries({
                queryKey: [QUERY_KEYS.ACCOUNT, { accountId }],
              });
            },
            onError: () => {
              toast.error("Error updating form.");
            },
          }
        );
        if (webGqlFormTemplatesMigration === "dualwrite") {
          await syncFormTemplate(
            { accountId },
            {
              onSuccess: () => {
                toast.success("Account Forms Synced");
              },
              onError: () => {
                toast.error("Error Syncing. Please contacf web team.");
              },
            }
          );
        }
      },
      open: true,
      title: "Save Changes",
    });
  };

  useEffect(() => {
    setFormText(JSON.stringify(form, null, 2));
  }, [form]);

  const mutationPending =
    editFormMutation.isPending || syncFormTemplatePending || editFormGqlPending;

  const pageLoading = accountLoading || formTemplateLoading;
  const jsonIndicatorText =
    webGqlFormTemplatesMigration === "live" ? "SQL" : "DynamoDB";
  return (
    <div className="w-full max-w-[1000px] pt-14">
      <a
        className={`mb-4 flex w-fit items-center gap-x-1 text-theme-black-secondary`}
        onClick={(e) => {
          e.preventDefault();
          navigate(`/admin/forms/${formid}`);
        }}
        href={`/admin/forms/${formid}`}
      >
        <ArrowLeftIcon className="h-6 w-6" />
        <span className="text-xs font-medium">Back to {formName} Detail</span>
      </a>
      <header className="mb-5 flex w-full flex-row justify-between">
        <h1 className="my-auto text-2xl font-semibold">
          Update {formName} Raw JSON
        </h1>
        <div className="my-4 flex justify-end">
          <div className="flex space-x-2">
            <Button
              label="Reset"
              variant="secondary"
              className="ml-auto rounded-full"
              onClick={() => {
                setFormText(JSON.stringify(form, null, 2));
                setIsInvalidJSON(false);
                setIsDirty(false);
              }}
            />
            <Button
              label="Save"
              variant="primary"
              disabled={isInvalidJSON || !isDirty || mutationPending}
              className="ml-auto rounded-full"
              loading={mutationPending}
              onClick={() => {
                handleSave();
              }}
            />
          </div>
        </div>
      </header>
      {pageLoading && <LoadingSpinner />}
      <div>
        <div className="relative">
          <textarea
            className={classNames(
              "h-[70vh] w-full border-2 focus:outline-none focus:ring-0",
              isInvalidJSON && "!border-red-500 ring-red-500"
            )}
            onChange={(e) => {
              try {
                JSON.parse(e.target.value);
                setIsInvalidJSON(false);
              } catch (error) {
                setIsInvalidJSON(true);
              }
              setFormText(e.target.value);
              setIsDirty(true);
            }}
            value={formText}
          />

          <span className="absolute right-2 top-2 flex content-center justify-center text-xs text-gray-600">
            <TableCellsIcon height={15} width={15} className="mr-1" />
            {jsonIndicatorText} JSON
          </span>
        </div>
      </div>
      <Alert {...alertProps} />
    </div>
  );
};

export default AdminEditRawFormPage;
