import { Divider } from "@/UI/Divider";
import { DashboardPinIcon, EditIcon } from "@/assets";
import { SettingsBranchModal } from "@/components/Settings/SettingsBranchModal";
import { useBranches } from "@/lib/react-query/queryHooks/useBranches";
import { useDivisions } from "@/lib/react-query/queryHooks/useDivisions";
import { Disclosure, Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import * as React from "react";
import { useState } from "react";

export const SettingsPage: React.FC = () => {
  const { data: branches } = useBranches();
  const { data: divisions } = useDivisions();
  const [branchModal, setBranchModal] = useState<{
    open: boolean;
    branchId?: string;
    divisionId?: string;
  }>({
    open: false,
    branchId: undefined,
    divisionId: undefined,
  });
  const getBranchesByDivisionId = (divisionId?: string) => {
    if (!divisionId) return [];
    return (
      branches?.data.filter((branch) => branch.divisionId === divisionId) ?? []
    );
  };
  return (
    <div className="w-full">
      <header className="mt-12  gap-y-4 ">
        <h1 className="text-4xl font-semibold">Account Settings</h1>
        <div className="my-10 w-full  rounded-2xl ">
          <h3 className="my-4 text-3xl font-semibold">Divisions</h3>
          <Divider />
          <div className="my-4 grid grid-cols-2 gap-10">
            {divisions?.data.map((division) => (
              <div
                key={division.id}
                className="group flex h-fit max-w-[450px] flex-col rounded-lg border-2 border-gray-300 shadow-xl"
              >
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className=" flex w-full justify-between rounded-t-lg  px-4 py-4 text-left text-sm font-medium transition-all focus:outline-none  focus-visible:ring focus-visible:ring-purple-500/75 group-hover:bg-gray-300/20">
                        <div className="flex flex-col justify-between space-y-3">
                          <h2 className="text-2xl font-semibold">
                            {division.divisionName}
                          </h2>
                          <p>
                            Branches (
                            {getBranchesByDivisionId(division?.id).length})
                          </p>
                        </div>
                        <ChevronUpIcon
                          className={`${
                            open ? "rotate-180 transform" : ""
                          } my-auto  h-5 transition-transform `}
                        />
                      </Disclosure.Button>
                      <Transition
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-0 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <Disclosure.Panel className="rounded-b-lg  p-4 text-sm text-gray-500">
                          <div className="flex flex-col justify-center space-y-4">
                            {getBranchesByDivisionId(division?.id).map(
                              (branch) => (
                                <div
                                  key={branch.id}
                                  className="relative flex space-x-2 rounded-md border border-gray-300 p-4"
                                >
                                  <img
                                    src={`${branch.logo}?etag=${new Date()
                                      .getTime()
                                      .toString()}`}
                                    alt={branch.name}
                                    className="h-10 w-10 rounded-full"
                                  />
                                  <div className="flex-1 space-y-2">
                                    <h3 className="text-lg text-black">
                                      {branch.name}
                                    </h3>
                                    <p>{branch.city}</p>
                                    <div className="flex space-x-2">
                                      <PhoneIcon className="h-5 w-5 text-black" />
                                      <p>{branch.phoneNumber}</p>
                                    </div>
                                    <div className="flex space-x-2">
                                      <EnvelopeIcon className="h-5 w-5 text-black" />
                                      <p>{branch.email}</p>
                                    </div>
                                    <div className="flex space-x-2">
                                      <DashboardPinIcon className="h-5 w-5 text-black" />
                                      <p>{branch.address}</p>
                                    </div>
                                  </div>
                                  <button
                                    className="absolute right-5 top-2 rounded-lg p-2 transition hover:bg-gray-200"
                                    onClick={() =>
                                      setBranchModal({
                                        open: true,
                                        branchId: branch.id,
                                        divisionId: division.id,
                                      })
                                    }
                                  >
                                    <EditIcon fill="black" />
                                  </button>
                                </div>
                              )
                            )}
                            <button
                              className="mt-4 rounded-lg bg-black px-4 py-2 text-white"
                              onClick={() =>
                                setBranchModal({
                                  open: true,
                                  divisionId: division.id,
                                })
                              }
                            >
                              + Add Branch
                            </button>
                          </div>
                        </Disclosure.Panel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
              </div>
            ))}
          </div>
        </div>
      </header>
      <SettingsBranchModal
        branchModal={branchModal}
        setBranchModal={setBranchModal}
      />
    </div>
  );
};
