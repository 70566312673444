import { Button } from "@/UI/Button";
import { LoadingSpinner } from "@/UI/Loading";
import { MouseEvent, useEffect } from "react";
import { AdminUserForm } from "@/components/Admin";
import { toast } from "react-toastify";
import { useAuthContext } from "@/components/Auth/AuthWrapper";
import { useLocation, useNavigate } from "react-router-dom";
import { useNotFoundStoreSelectors } from "@/lib/zustand/notFoundStore";
import { useUser } from "@/lib/react-query/queryHooks/useUser";

const AUTH0_LABEL = "Copy Auth0 ID to Clipboard";
const RIBBIOT_LABEL = "Copy Ribbiot ID to Clipboard";

const AdminUserDetailsPage = () => {
  const navigate = useNavigate();
  const { hasRibbiotAdmin } = useAuthContext();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get("userId") ?? "";
  const setNotFound = useNotFoundStoreSelectors.use.setNotFound();

  const { data: userData, isError } = useUser({
    userId,
    options: { enabled: !!userId && userId !== "" },
  });

  const showUserIdentifier = hasRibbiotAdmin
    ? {
        ribbiotID: userData?.data.id,
        authOId: userData?.data?.auth0UserId,
      }
    : undefined;

  const copyToClipboard = (event: MouseEvent<HTMLButtonElement>) => {
    switch (event.currentTarget.id) {
      case AUTH0_LABEL:
        navigator.clipboard.writeText(showUserIdentifier?.authOId || "");
        toast.success("Auth0 ID Copied to Clipboard");
        break;
      case RIBBIOT_LABEL:
        navigator.clipboard.writeText(showUserIdentifier?.ribbiotID || "");
        toast.success("Ribbiot ID Copied to Clipboard");
        break;
      default:
        break;
    }
  };

  if (!userId) navigate("/users");

  useEffect(() => {
    if (isError) {
      setNotFound({ notFound: true, message: "No User Found" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return (
    <div className="w-full bg-transparent">
      <div className="h-screen items-center overflow-y-auto">
        <h1 className="mb-12 mt-24 text-4xl font-semibold">
          {userData?.data &&
            `${userData.data.firstName} ${userData.data.lastName}`}
        </h1>
        {showUserIdentifier && userData && (
          <div className="flex items-center">
            <h3 className="mr-4 w-96">
              Ribbiot ID: {showUserIdentifier?.ribbiotID}
            </h3>
            <Button
              className="rounded-md"
              label={RIBBIOT_LABEL}
              variant="primary"
              onClick={copyToClipboard}
            >
              Copy to Clipboard
            </Button>
          </div>
        )}
        {showUserIdentifier && userData && (
          <div className="flex items-center">
            <h3 className="mr-4 w-96">
              Auth0 ID: {showUserIdentifier?.authOId}
            </h3>
            <Button
              className="rounded-md"
              label={AUTH0_LABEL}
              variant="primary"
              onClick={copyToClipboard}
            >
              Copy to Clipboard
            </Button>
          </div>
        )}
        <br />
        {userData ? (
          <AdminUserForm edit userData={userData?.data} />
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </div>
  );
};
export default AdminUserDetailsPage;
