import { LoadingSpinner } from "../UI/Loading";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuthContext } from "../components/Auth/AuthWrapper";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const LoginPage = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const {
    hasTimecardAdmin,
    hasAssetCrud,
    hasRibbiotAdmin,
    hasUserCrud,
    hasWebAssetMap,
    hasWebSchedule,
    hasWebQuoting,
    hasWebSettings,
  } = useAuthContext();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleLoginRedirect = async () => {
    if (!isAuthenticated) {
      loginWithRedirect();
      return;
    }
    if (pathname !== "/") {
      navigate(window.location.origin);
      return;
    }
    if (hasRibbiotAdmin) {
      navigate("/schedule");
      return;
    }
    if (hasWebAssetMap) {
      navigate("/map");
      return;
    }
    if (hasTimecardAdmin) {
      navigate("/documents");
      return;
    }
    if (hasAssetCrud) {
      navigate("/assets/browser");
      return;
    }
    if (hasUserCrud) {
      navigate("/users");
      return;
    }
    if (hasWebSchedule) {
      navigate("/schedule");
      return;
    }
    if (hasWebQuoting) {
      navigate("/quoting");
      return;
    }
    if (hasWebSettings) {
      navigate("/settings");
      return;
    }
    navigate("/404");
  };

  useEffect(() => {
    if (hasRibbiotAdmin === undefined) return;
    handleLoginRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, hasRibbiotAdmin]);

  return <LoadingSpinner />;
};

export default LoginPage;
