import { ControlledInput } from "@/UI/Input";
import { LoadingSpinner } from "@/UI/Loading";
import { Modal } from "@/UI/Modal";
import { Select } from "@/UI/Select";
import { branchFormDefaultValues } from "@/components/Admin/helpers";
import { QUERY_KEYS } from "@/lib/react-query/constants";
import { queryClient } from "@/lib/react-query/general";
import { useCreateBranch } from "@/lib/react-query/mutationHooks/useCreateBranch";
import { useEditBranch } from "@/lib/react-query/mutationHooks/useEditBranch";
import { useBranch } from "@/lib/react-query/queryHooks/useBranch";
import { useDivisions } from "@/lib/react-query/queryHooks/useDivisions";
import {
  BranchFormSchema,
  branchFormSchema,
  getBranchFormValues,
} from "@/utils/formDefinitions/branches/general";
import { classNames } from "@/utils/helpers/classNames";
import { zodResolver } from "@hookform/resolvers/zod";
import { Dispatch, FC, SetStateAction, useEffect } from "react";
import { FieldErrors, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";

interface AddBranchModalProps {
  branchModal: {
    open: boolean;
    branchId?: string | undefined;
    divisionId?: string;
  };
  setBranchModal: Dispatch<
    SetStateAction<{
      open: boolean;
      branchId?: string | undefined;
      divisionId?: string;
    }>
  >;
}
const SettingsBranchModal: FC<AddBranchModalProps> = ({
  branchModal,
  setBranchModal,
}) => {
  const { open, branchId, divisionId } = branchModal;
  const edit = !!branchModal.branchId;
  const editBranch = useEditBranch();
  const createBranch = useCreateBranch();
  const { data: divisions } = useDivisions();

  const divisionOptions =
    divisions?.data.map((division) => ({
      id: division.id ?? "",
      value: division.divisionName ?? "",
    })) ?? [];

  const { data: branchData, isLoading: branchLoading } = useBranch({
    branchId: branchId ?? "",
  });

  const {
    handleSubmit,
    reset,
    formState,
    getValues,
    setValue,
    setError,
    control,
    watch,
  } = useForm<BranchFormSchema>({
    defaultValues: {
      ...branchFormDefaultValues,
    },
    resolver: zodResolver(branchFormSchema),
  });

  const selectedDivision = divisionOptions?.find(
    (divisionOption) => divisionOption.id === getValues("divisionId")
  );

  const logo = watch("logo") || "";

  const handleLogoToShow = (img: string) => {
    if (img.startsWith("data")) return img;
    const hasQuestionMark = img.includes("?");
    if (hasQuestionMark) {
      const parsed = img.split("?")[0];
      return `${parsed}?etag=${new Date().getTime().toString()}`;
    }
    return `${img}?etag=${new Date().getTime().toString()}`;
  };

  useEffect(() => {
    reset(getBranchFormValues(branchData));
    return () => {
      reset(branchFormDefaultValues);
    };
  }, [edit, branchData, reset, divisionId, setValue]);

  const selectImg = (field: "quoteCover" | "logo") => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.addEventListener("change", (e) => {
      const selection = (e.target as HTMLInputElement).files?.[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        setValue(field, event?.target?.result?.toString() || "", {
          shouldDirty: true,
        });
      };
      reader.readAsDataURL(selection!);
    });
    input.click();
    input.remove();
  };

  const handleImageToSend = (img: string, type: "quoteCover" | "logo") => {
    if (type === "logo" && branchData?.logo === img) return undefined;
    if (type === "quoteCover" && branchData?.quoteCover === img) {
      return undefined;
    }
    const hasComma = img.includes(",");
    if (hasComma) return img.split(",")[1];
    return img;
  };

  const onSubmit: SubmitHandler<BranchFormSchema> = (data) => {
    if (!data.logo) {
      setError("logo", { message: "Required" });
      return;
    }
    if (edit) {
      if (!branchData) return;
      editBranch.mutate(
        {
          body: {
            ...data,
            logo: handleImageToSend(logo, "logo"),
          },
          branchId: branchData?.id,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.DIVISIONS] });
            setBranchModal({ open: false, branchId, divisionId });
            toast.success("Branch edited");
          },
          onError: () => {
            toast.error("Error editing branch");
          },
        }
      );
      return;
    }
    createBranch.mutate(
      {
        body: {
          ...data,
          logo: handleImageToSend(logo, "logo") as string,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.DIVISIONS] });
          setBranchModal({ open: false, branchId, divisionId });
          toast.success("Branch created");
        },
        onError: () => {
          toast.error("Error creating branch");
        },
      }
    );
  };
  const onError = (error: FieldErrors<BranchFormSchema>) =>
    console.error(error);

  const loadingState =
    editBranch.isPending || createBranch.isPending || branchLoading;
  return (
    <Modal
      open={open}
      innerContainerClasses="!w-[500px] text-2xl font-semibold"
      onCancel={() => {
        setBranchModal({ open: false, branchId, divisionId });
        reset();
      }}
      disableConfirm={!formState.isDirty || loadingState}
      confirmButtonText="Confirm"
      onConfirm={handleSubmit(onSubmit, onError)}
    >
      {loadingState && (
        <div className="relative z-50 translate-x-[200px] translate-y-[10px]">
          <LoadingSpinner />
        </div>
      )}
      <h1 className="mb-8 text-2xl font-semibold">
        {branchId ? "Edit Branch" : "Add New Branch"}
      </h1>
      <div
        className={classNames(
          "my-4 flex content-center justify-center space-x-5",
          formState.errors.logo ? "border border-red-600" : ""
        )}
      >
        {logo ? (
          <img
            className="h-28 w-28 rounded-full border-2 bg-contain bg-no-repeat"
            src={handleLogoToShow(logo)}
            alt="Branch logo"
          />
        ) : (
          <div className="h-28 w-28 rounded-full bg-gray-100" />
        )}
        <div className="my-auto flex-1 space-y-3">
          <p className="text-lg font-medium">Branch Image</p>
          <div className="space-x-2">
            <button
              onClick={() => selectImg("logo")}
              className="border-2 px-4 py-2 text-sm font-normal"
            >
              Choose Image
            </button>
          </div>
        </div>
        {formState.errors.logo && (
          <p className=" text-[12px] font-medium text-theme-red-primary">
            {formState.errors.logo.message}
          </p>
        )}
      </div>

      <div className="w-full space-y-4">
        <ControlledInput control={control} name="name" label="Branch Name" />
        <Select
          label="Division"
          options={divisionOptions}
          value={selectedDivision}
          onChange={(newDivision) => {
            setValue("divisionId", newDivision?.id);
          }}
        />
        <ControlledInput label="Email" control={control} name="email" />
        <ControlledInput
          label="Phone Number"
          control={control}
          name="phoneNumber"
        />
        <ControlledInput label="Address" control={control} name="address" />
        <div className="grid grid-cols-3 space-x-3">
          <ControlledInput label="City" control={control} name="city" />
          <ControlledInput label="State" control={control} name="state" />
          <ControlledInput
            label="Postal Code"
            control={control}
            name="postalCode"
          />
        </div>
      </div>
    </Modal>
  );
};

export { SettingsBranchModal };
