import { FC } from "react";

interface DashboardPinIconProps {
  className?: string;
}
const DashboardPinIcon: FC<DashboardPinIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
    >
      <path
        d="M7.00035 0.897095C3.66802 0.897095 0.957031 3.60918 0.957031 6.94281C0.957031 11.9097 6.39882 16.7595 6.63056 16.9635C6.7362 17.0566 6.86825 17.1032 7.00035 17.1032C7.13245 17.1032 7.2645 17.0566 7.3702 16.9635C7.60183 16.7595 13.0437 11.9098 13.0437 6.94281C13.0437 3.60918 10.3327 0.897095 7.00035 0.897095ZM7.00035 15.7766C5.79723 14.6107 2.07617 10.7004 2.07617 6.94281C2.07617 4.22631 4.28516 2.01624 7.00035 2.01624C9.71554 2.01624 11.9245 4.22631 11.9245 6.94281C11.9245 10.7003 8.20347 14.6107 7.00035 15.7766Z"
        fill="#575757"
      />
      <path
        d="M6.99898 4.12628C5.45624 4.12628 4.20117 5.3897 4.20117 6.94264C4.20117 8.49563 5.45624 9.75899 6.99898 9.75899C8.54171 9.75899 9.79678 8.49563 9.79678 6.94264C9.79678 5.3897 8.54171 4.12628 6.99898 4.12628ZM6.99898 8.63985C6.07332 8.63985 5.32026 7.87849 5.32026 6.94264C5.32026 6.00684 6.07332 5.24543 6.99898 5.24543C7.92463 5.24543 8.67769 6.00678 8.67769 6.94264C8.67769 7.87849 7.92463 8.63985 6.99898 8.63985Z"
        fill="#575757"
      />
    </svg>
  );
};

export { DashboardPinIcon };
