import { AssetsAsset } from "@/types/assetInventory/assets/general";
import { QUERY_KEYS } from "@/lib/react-query/constants";
import { queryClient } from "@/lib/react-query/general";

export const handleAssetCacheProcess = async (asset: AssetsAsset) => {
  queryClient.invalidateQueries({
    queryKey: [QUERY_KEYS.ASSET, { assetId: asset.id }],
    type: "all",
  });

  const categoryChildrenData: { data: AssetsAsset[] } | undefined =
    await queryClient.getQueryData([
      QUERY_KEYS.CATEGORY_CHILDREN,
      { categoryId: asset.categoryId },
    ]);

  const clonedCategoryChildrenData = structuredClone(
    categoryChildrenData?.data
  );

  const index = clonedCategoryChildrenData?.findIndex(
    (item) => item.id === asset.id
  );

  if (index !== undefined && index >= 0 && clonedCategoryChildrenData) {
    clonedCategoryChildrenData[index] = asset;

    queryClient.setQueryData(
      [QUERY_KEYS.CATEGORY_CHILDREN, { categoryId: asset.categoryId }],
      { data: clonedCategoryChildrenData }
    );
  }
};
