import { Branch } from "@/types/users/general";
import { z } from "zod";

export const branchFormSchema = z.object({
  address: z.string().min(1, { message: "Required" }),
  city: z.string().min(1, { message: "Required" }),
  divisionId: z.string().uuid().optional(),
  email: z.string().min(1, { message: "Required" }),
  logo: z.string().optional(),
  name: z.string().min(1, { message: "Required" }),
  phoneNumber: z
    .string()
    .min(1, { message: "Required" })
    .regex(
      new RegExp(/^[(]?[0-9]{3}[)]?[ ,-]?[0-9]{3}[ ,-]?[0-9]{4}$/),
      "Phone number not in correct format. Ex: ###-###-####"
    ),
  postalCode: z.string().min(1, { message: "Required" }),
  quoteCover: z.string().optional(),
  state: z.string().min(1, { message: "Required" }),
});
export type BranchFormSchema = z.infer<typeof branchFormSchema>;
export const getBranchFormValues = (branchData: Branch | undefined) => {
  return {
    address: branchData?.address || "",
    city: branchData?.city || "",
    divisionId: branchData?.divisionId || "",
    email: branchData?.email || "",
    logo: branchData?.logo,
    name: branchData?.name || "",
    phoneNumber: branchData?.phoneNumber || "",
    postalCode: branchData?.postalCode || "",
    quoteCover: branchData?.quoteCover,
    state: branchData?.state || "",
  };
};
