import { LoadingSpinner } from "@/UI/Loading";
import { useAuthContext } from "@/components/Auth/AuthWrapper";
import { useAccount } from "@/lib/react-query/queryHooks/useAccount";
import { useDivisions } from "@/lib/react-query/queryHooks/useDivisions";
import { Link, useNavigate } from "react-router-dom";
import { MouseEvent as ReactMouseEvent } from "react";
import {
  ArrowLeftIcon,
  ClipboardDocumentIcon,
} from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import { Button } from "@/UI/Button";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useFormTemplates } from "@/lib/react-query/queryHooks/useFormTemplates";

const AdminAccountFormsPage = () => {
  const { webGqlFormTemplatesMigration } = useFlags();
  const { accountId } = useAuthContext();

  const useGQLFormTemplates =
    webGqlFormTemplatesMigration === "live" ||
    webGqlFormTemplatesMigration === "dualwrite";

  const {
    isLoading: formTemplatesLoading,
    isFetching: formTemplatesFetching,
    data: formTemplates,
  } = useFormTemplates({
    options: { staleTime: 5 * 1000 * 60, enabled: useGQLFormTemplates },
  });

  const {
    data: accountData,
    isLoading: accountLoading,
    isFetching: accountFetching,
  } = useAccount({
    accountId,
    options: { staleTime: 5 * 1000 * 60 },
  });

  const { data: divisions } = useDivisions({
    options: { staleTime: 5 * 1000 * 60 },
  });

  const navigate = useNavigate();
  const getDivisionNameById = (divisionId: string | undefined) => {
    if (!divisionId) return undefined;
    return divisions?.data.find((division) => division.id === divisionId)
      ?.divisionName;
  };

  const handleNavigation = (
    event: ReactMouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>,
    formId: string
  ) => {
    event.preventDefault();
    navigate(formId);
  };

  const hasDynamicDocument = (formId: string) => {
    return accountData?.data.dynamicPages?.formDocumentsPage.find(
      (dynamicPage) => dynamicPage.formId === formId
    );
  };

  const getNoFormsFound = () => {
    if (useGQLFormTemplates) {
      return (
        !!formTemplates?.data?.formTemplates &&
        formTemplates?.data?.formTemplates?.length < 1 &&
        !formTemplatesLoading
      );
    }
    return (
      (!accountData?.data.forms || accountData.data.forms.length < 1) &&
      !accountLoading
    );
  };
  const getPageLoading = () => {
    if (useGQLFormTemplates) {
      return formTemplatesLoading || formTemplatesFetching;
    }
    return accountLoading || accountFetching;
  };

  const getFormTemplates = () => {
    if (useGQLFormTemplates) {
      return formTemplates?.data?.formTemplates;
    }
    return accountData?.data.forms;
  };

  return (
    <div className="w-full max-w-[1600px] pt-14">
      <header className="mb-5 flex justify-between">
        <div>
          <Link
            className="my-4 flex w-fit items-center gap-x-1 text-theme-black-secondary"
            to="/admin"
          >
            <ArrowLeftIcon className="h-6 w-6" />
            <span className="text-xs font-medium">Back to Account</span>
          </Link>
          <h1 className="text-4xl font-semibold">Account Forms</h1>
        </div>
        <Link className="mt-auto" to={`/admin/forms/create`}>
          <Button
            label="Create Form"
            className="mt-auto rounded-md text-white"
            variant="primary"
          />
        </Link>
      </header>
      <div className="grid grid-cols-2 gap-4">
        {getPageLoading() && <LoadingSpinner />}
        {getNoFormsFound() && <h2>No Forms Found</h2>}
        {getFormTemplates()?.map((form) => {
          return (
            <div className="my-7" key={form?.id}>
              <a
                href={`/admin/forms/${form?.id}`}
                onClick={(event) => handleNavigation(event, form?.id ?? "")}
                title={form?.id}
              >
                <div className="relative h-44  rounded-xl border-4 border-green-400 p-4 transition-all hover:bg-theme-green-quarternary">
                  <h3 className="text-left text-xl font-semibold">
                    {form?.fields?.find(
                      (field) => field?.name === "Form Description"
                    )?.text ?? form?.name}
                  </h3>
                  <mark className="flex space-x-2 bg-transparent py-1">
                    <span className="font-semibold">ID:</span>
                    <p>{form?.id}</p>
                  </mark>
                  {form?.name && (
                    <div className="flex space-x-2">
                      <span className="font-semibold">Name:</span>
                      <p>{form.name}</p>
                    </div>
                  )}
                  {form?.divisionId && (
                    <div className="flex space-x-2">
                      <span className="font-semibold">Division:</span>
                      <p>{getDivisionNameById(form.divisionId)}</p>
                    </div>
                  )}
                  {hasDynamicDocument(form?.id ?? "") && (
                    <p className="mt-2 w-fit whitespace-nowrap rounded-full border-2 border-gray-500 px-4 py-[2px] text-xs">
                      + Dynamic Document
                    </p>
                  )}
                  <button
                    title="Copy form to Clipboard"
                    className="absolute right-5 top-5 rounded-full bg-white p-2"
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      navigator.clipboard.writeText(JSON.stringify(form));
                      toast.success("Form Copied!");
                    }}
                  >
                    <ClipboardDocumentIcon width={20} height={20} />
                  </button>
                </div>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AdminAccountFormsPage;
