export const AMPLITUDE_EVENTS = {
  ACCOUNT_PERMISSIONS_UPDATED: "account_permissions_updated",
  ASSET_CREATED: "asset_created",
  ASSET_DOCUMENT_OPENED: "asset_document_opened",
  ASSET_EDIT_ENTERED: "asset_edit_entered",
  ASSET_SEARCHED: "asset_searched",
  ASSET_SELECTED: "asset_selected",
  ASSET_UPDATED: "asset_updated",
  CATEGORY_CREATED: "category_created",
  DOCUMENT_ADDED: "document_added",
  ERROR_PAGE_VIEWED: "error_page_viewed",
  FORM_ADMIN_NOTE_ADDED: "form_admin_note_added",
  FORM_BULK_PDF_DOWNLOADED: "form_bulk_pdf_downloaded",
  FORM_CSV_DOWNLOADED: "form_csv_downloaded",
  FORM_EDIT_ENTERED: "form_edit_entered",
  FORM_EDITED: "form_edited",
  FORM_FILTERED: "form_filtered",
  FORM_PDF_DOWNLOADED: "form_pdf_downloaded",
  FORM_STATUS_CHANGED: "form_status_changed",
  FORM_VIEWED: "form_viewed",
  HELP_CENTER_VIEWED: "help_center_viewed",
  IMAGE_ADDED: "image_added",
  JOB_ASSET_MAP_INTERACTED: "job_asset_map_interacted",
  JOB_ASSET_SELECTED: "job_asset_selected",
  JOB_ASSET_SELECTION_ENTERED: "job_asset_selection_entered",
  JOB_COLOR_UPDATED: "job_color_updated",
  JOB_CREATED: "job_created",
  JOB_CREW_SELECTED: "job_crew_selected",
  JOB_CREW_SELECTION_ENTERED: "job_crew_selection_entered",
  JOB_DASHBOARD_ENTERED: "job_dashboard_entered",
  JOB_DATE_UPDATED: "job_date_updated",
  JOB_DETAILS_ENTERED: "job_details_entered",
  JOB_DETAILS_UPDATED: "job_details_updated",
  JOB_DOCUMENT_DELETED: "job_document_deleted",
  JOB_DOCUMENT_FAILED: "job_document_failed",
  JOB_DOCUMENT_OPENED: "job_document_opened",
  JOB_DOCUMENT_UPLOADED: "job_document_uploaded",
  JOB_EDITED: "job_edited",
  JOB_NOTES_UPDATED: "job_notes_updated",
  JOB_STATUS_UPDATED: "job_status_updated",
  LOGIN: "login",
  LOGOUT: "logout",
  OOPS_PAGE_VIEWED: "oops_page_viewed",
  QUOTE_CONVERTED: "quote_converted",
  QUOTE_COPIED: "quote_copied",
  QUOTE_CREATED: "quote_created",
  QUOTE_EDITED: "quote_edited",
  USER_CREATED: "user_created",
  USER_CREATION_FAILED: "user_creation_failed",
  USER_CREATION_STARTED: "user_creation_started",
  USER_DEVICE_DIMENSIONS: "user_device_dimensions",
};
