import { AdminDivisionForm } from "@/components/Admin";
import { useDivision } from "@/lib/react-query/queryHooks/useDivision";
import { useLocation } from "react-router-dom";

const AdminDivisionPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const divisionId = searchParams.get("divisionId") ?? "";
  const { data: division } = useDivision({ divisionId });

  return (
    <div className="w-full bg-transparent">
      <div className="h-screen items-center overflow-y-auto">
        <h1 className="mb-12 mt-24 text-4xl font-semibold">Division Admin</h1>
        <AdminDivisionForm divisionData={division} edit={true} />
      </div>
    </div>
  );
};

export default AdminDivisionPage;
