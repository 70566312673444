/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DisplayMap,
  useFormRendererStore,
} from "@/lib/zustand/formRendererStore";
import {
  RowField,
  RowFieldAttributes,
  RowFieldType,
} from "@/types/forms/formEngine";
import { isDate, isValid, parseISO } from "date-fns";
import { get } from "../lodashCopies";
import {
  formatDateWithinTimezoneOffset,
  getFormattedTimezoneOffset,
} from "../dateFormat";
import { formatInTimeZone, zonedTimeToUtc } from "date-fns-tz";
import { EditedField } from "@/components/Forms/FormEngine/ChangesModal";
import { ElementType } from "react";
import { CheckboxField } from "@/components/Forms/FormEngine/Fields/CheckboxField";
import { AdderField } from "@/components/Forms/FormEngine/Fields/AdderField";
import { BranchField } from "@/components/Forms/FormEngine/Fields/BranchField";
import { Field } from "@/components/Forms/FormEngine/Fields/FIeld";
import { MultiUserField } from "@/components/Forms/FormEngine/Fields/MultiUserField";
import { RadioField } from "@/components/Forms/FormEngine/Fields/RadioField";
import { SignatureField } from "@/components/Forms/FormEngine/Fields/SignatureField";
import { TimeField } from "@/components/Forms/FormEngine/Fields/TimeField";
import { TotalField } from "@/components/Forms/FormEngine/Fields/TotalField";
import { EditCheckboxField } from "@/components/Forms/FormEngine/Fields/EditCheckboxField";
import { EditTimeField } from "@/components/Forms/FormEngine/Fields/EditTimeField";
import { EditAdderField } from "@/components/Forms/FormEngine/Fields/EditAdderField";
import { EditMultiUserField } from "@/components/Forms/FormEngine/Fields/EditMultiUserField";
import { EditTotalField } from "@/components/Forms/FormEngine/Fields/EditTotalField";
import { EditField } from "@/components/Forms/FormEngine/Fields/EditFIeld";
import { EditNumberField } from "@/components/Forms/FormEngine/Fields/EditNumberField";
import { EditTextAreaField } from "@/components/Forms/FormEngine/Fields/EditTextAreaField";
import { EditBranchField } from "@/components/Forms/FormEngine/Fields/EditBranchField";
import { Null } from "@/components/Forms/FormEngine/Fields/Null";
import { MultiImageField } from "@/components/Forms/FormEngine/Fields/MultiImageField";
import { EditMultiImageField } from "@/components/Forms/FormEngine/Fields/EditMultiImageField";
import { UseFormReturn } from "react-hook-form";
import { DateField } from "@/components/Forms/FormEngine/Fields/DateField";
import { DivisionField } from "@/components/Forms/FormEngine/Fields/DivisionField";
import { MultiQuoteItemField } from "@/components/Forms/FormEngine/Fields/QuoteItems/MultiQuoteItemField";
import { EditDateField } from "@/components/Forms/FormEngine/Fields/EditDateField";
import { EditDivisionField } from "@/components/Forms/FormEngine/Fields/EditDivisionField";
import { EditMultiQuoteItemField } from "@/components/Forms/FormEngine/Fields/QuoteItems/EditMultiQuoteItemField";
import { UserField } from "@/components/Forms/FormEngine/Fields/UserField";
import { EditUserField } from "@/components/Forms/FormEngine/Fields/EditUserField";
import { PickerField } from "@/components/Forms/FormEngine/Fields/PickerField";
import { EditPickerField } from "@/components/Forms/FormEngine/Fields/EditPickerField";
import { EditLocationField } from "@/components/Forms/FormEngine/Fields/EditLocationField";
import { LocationField } from "@/components/Forms/FormEngine/Fields/LocationField";

export const getLayoutClassesByAttributes = (
  attributes: RowFieldAttributes | undefined
) => {
  if (!attributes) return "";
  const classesArray = [];
  if (attributes?.marginTop === "small") classesArray.push("mt-4");
  if (attributes?.marginTop === "medium") classesArray.push("mt-6");
  if (attributes?.marginTop === "large") classesArray.push("mt-8");
  if (attributes?.marginBottom === "medium") classesArray.push("mb-6");
  if (attributes?.marginBottom === "small") classesArray.push("mb-2");
  if (attributes?.marginX === "medium") classesArray.push("mx-6");
  if (attributes?.marginY === "small") classesArray.push("!my-4");
  if (attributes?.marginY === "medium") classesArray.push("my-6");
  if (attributes?.marginY === "large") classesArray.push("my-8");
  return classesArray.join(" ");
};

export const getTitleClassesByAttributes = (
  attributes: RowFieldAttributes | undefined
) => {
  if (!attributes) return "";
  const classesArray = [];
  if (attributes.titleTextSize === "large")
    classesArray.push("!text-xl !text-black");
  if (attributes.titleTextSize === "medium") classesArray.push("!text-base");
  if (attributes.titleTextSize === "small") classesArray.push("!text-sm");
  if (attributes.nowrap) classesArray.push("whitespace-nowrap");
  return classesArray.join(" ");
};

export const getValueClassesByAttributes = (
  attributes: RowFieldAttributes | undefined
) => {
  if (!attributes) return "";
  const classesArray = [];
  if (attributes.valueTextSize === "medium") classesArray.push("!text-base");
  if (attributes.valueTextSize === "small") classesArray.push("!text-sm");
  if (attributes.valueTextWeight === "light") classesArray.push("!font-light");
  if (attributes.nowrap) classesArray.push("whitespace-nowrap");
  return classesArray.join(" ");
};

export const transformTitleByFieldItem = (fieldItem: RowField | undefined) => {
  if (fieldItem?.attributes?.hideTitle) return undefined;
  if (fieldItem?.attributes?.title) return fieldItem.attributes.title;
  return getFieldsObjectById(fieldItem?.id)?.name;
};

export const getObjectDifferences = (
  beforeObject: Record<string, any>,
  afterObject: Record<string, any>
) => {
  const before: Record<string, any> = {};
  const after: Record<string, any> = {};

  // Helper function to check if two values are the same
  const isEqual = (value1: any, value2: any) => {
    if (isDate(value1) && isDate(value2)) {
      return (value1 as Date).toUTCString() === (value2 as Date).toUTCString();
    }
    if (Number(value1) && Number(value2)) {
      return Number(value1) === Number(value2);
    }
    if (Array.isArray(value1) && Array.isArray(value2)) {
      return JSON.stringify(value1) === JSON.stringify(value2);
    }
    if (!value1 && !value2) return true;
    return value1 === value2;
  };

  // Check differences from beforeObject to afterObject
  Object.keys(beforeObject).forEach((key) => {
    if (!isEqual(beforeObject[key], afterObject[key])) {
      before[key] = beforeObject[key];
    }
  });

  // Check differences from afterObject to beforeObject
  Object.keys(afterObject).forEach((key) => {
    if (!isEqual(beforeObject[key], afterObject[key])) {
      after[key] = afterObject[key];
    }
  });

  return [before, after];
};

export const getFieldsObjectByName = (fieldName: string) => {
  return useFormRendererStore
    .getState()
    .formObject?.fields?.find((field: any) => field.name === fieldName);
};

export const getFormSubmissionValueById = (id: string | undefined) => {
  if (!id) return undefined;
  return useFormRendererStore
    .getState()
    .formSubmission?.fields.find((field) => field.id === id)?.value as any;
};

const findFieldsObjectById = (array: any[], targetId: string): any => {
  if (!array) return null;
  for (const obj of array) {
    // Check if the top-level object has the target ID
    if (obj.id === targetId) {
      return obj;
    }

    // Check if the object has a 'fields' property that is an array
    if (Array.isArray(obj.fields)) {
      // Recursively search the 'fields' array
      const found = findFieldsObjectById(obj.fields, targetId);
      if (found) {
        return found;
      }
    }
  }
  // If not found, return null
  return null;
};

export const getFieldsObjectById = (fieldId: string | undefined) => {
  if (!fieldId) return undefined;
  //This function is ugly but it works for now.
  const formObject = useFormRendererStore.getState().formObject as any;
  const fullFormObjectFields = formObject?.fields?.concat(
    formObject?.subForms?.flatMap((subForm: any) => subForm.fields ?? []) ?? [],
    formObject?.subforms?.flatMap((subForm: any) => subForm.fields ?? []) ?? [] // graphql uses subForms instead of subforms so we need to support both
  );
  return (
    findFieldsObjectById(fullFormObjectFields, fieldId) ?? { id: "", name: "" }
  );
};

export const evaluateExpression = (expression: string | undefined) => {
  if (!expression) return false;
  if (expression === "field(Job Location) = Shop") {
    const { id } = getFieldsObjectByName("Job Location") as any;
    const expressionFieldValue = getFormSubmissionValueById(id);
    return expressionFieldValue === "Shop";
  }
  if (expression === "field(load) !== true") {
    const { id } = getFieldsObjectByName("Job Load") as any;
    const expressionFieldValue = getFormSubmissionValueById(id);
    return expressionFieldValue !== "true";
  }
  return false;
};

/**
 * Used for displaying items on the changes modal
 * @returns
 * {
 *  id: string;
 *  displayName: string;
 *  endText?: string;
 * }[]
 */
export const getDisplayMap = () => {
  const displayMap: DisplayMap = [];
  useFormRendererStore
    .getState()
    .formObject?.layouts?.web?.fields?.map((layoutField: any) => {
      if (layoutField.type === "Divider") return;
      layoutField.fields.map((fieldItem: RowField) => {
        if (fieldItem.text) return;
        if (!fieldItem.id) {
          displayMap.push({
            id: fieldItem.path ?? "",
            displayName:
              fieldItem.attributes?.editTitle ??
              fieldItem.attributes?.title ??
              fieldItem.path ??
              "",
          });
          return;
        }
        const { name } = getFieldsObjectById(fieldItem.id);
        const title =
          fieldItem.attributes?.editTitle ??
          fieldItem.attributes?.title ??
          name;
        displayMap.push({
          id: fieldItem.id,
          displayName: title,
          endText: fieldItem?.attributes?.endText,
        });

        if (fieldItem.type === "MultiQuoteItemField") {
          displayMap.push({
            id: fieldItem?.attributes?.discountsId ?? "",
            displayName: "Discounts",
            endText: "$",
          });
          displayMap.push({
            id: fieldItem?.attributes?.surchargeId ?? "",
            displayName: "Surcharge Percentage",
            endText: "%",
          });
          displayMap.push({
            id: fieldItem?.attributes?.taxId ?? "",
            displayName: "Tax Percentage",
            endText: "%",
          });
        }
      });
    });
  return displayMap;
};

export const getDisplayValue = ({
  fieldId,
  value,
}: {
  fieldId: string;
  value: any;
}) => {
  const endText = useFormRendererStore
    .getState()
    .displayMap?.find((item) => item.id === fieldId)?.endText;
  return `${value} ${endText ?? ""}`;
};

export const getDisplayName = (fieldId: string) => {
  return useFormRendererStore
    .getState()
    .displayMap?.find((item) => item.id === fieldId)?.displayName;
};

export const documentDetailQueryToFormMapper = () => {
  const defaultValues: Record<string, any> = {};
  const { formObject, formSubmission } = useFormRendererStore.getState();
  const timezoneOffset =
    formSubmission?.timezoneOffset ?? getFormattedTimezoneOffset();

  formObject?.layouts?.web?.fields?.forEach((layoutField: any) => {
    if (layoutField.type === "Divider") return;

    layoutField.fields.forEach((fieldItem: RowField) => {
      if (!fieldItem?.id || fieldItem?.text) {
        if (fieldItem.path === "localDateTime") {
          const localDateTimeValue =
            get(formSubmission, "localDateTime") ?? new Date().toISOString();
          defaultValues["localDateTime"] = formatDateWithinTimezoneOffset(
            parseISO(localDateTimeValue),
            timezoneOffset
          );
        } else {
          defaultValues[`${fieldItem.path}`] = "";
        }
        return;
      }

      const value = getFormSubmissionValueById(fieldItem.id);

      switch (fieldItem.type) {
        case "DateField":
        case "TimeField":
        case "DateTimeField":
        case "WeekDateField": {
          const formattedDate = formatDateWithinTimezoneOffset(
            parseISO(value ?? ""),
            timezoneOffset
          );
          defaultValues[fieldItem.id] = isValid(formattedDate)
            ? formattedDate
            : undefined;
          break;
        }
        case "Checkbox": {
          defaultValues[fieldItem.id] = value !== "false" && Boolean(value);
          break;
        }
        case "TotalField": {
          const startValue = getFormSubmissionValueById(
            fieldItem.attributes?.startId ?? ""
          );
          const endValue = getFormSubmissionValueById(
            fieldItem.attributes?.endId ?? ""
          );
          defaultValues[fieldItem.id] =
            startValue && endValue ? value ?? "" : undefined;
          break;
        }
        case "MultiImageField":
        case "MultiUserField": {
          defaultValues[fieldItem.id] = value ?? [];
          break;
        }
        case "MultiQuoteItemField": {
          defaultValues[fieldItem.id] = value ?? [];
          defaultValues[`${fieldItem?.attributes?.discountsId}`] =
            getFormSubmissionValueById(fieldItem?.attributes?.discountsId) ??
            "";
          defaultValues[`${fieldItem?.attributes?.surchargeId}`] =
            getFormSubmissionValueById(fieldItem?.attributes?.surchargeId) ??
            "";
          defaultValues[`${fieldItem?.attributes?.taxId}`] =
            getFormSubmissionValueById(fieldItem?.attributes?.taxId) ?? "";
          break;
        }
        default:
          defaultValues[fieldItem.id] = value ?? "";
      }
    });
  });

  return defaultValues;
};

export const formatEditedFieldsForQuery = (
  editedFields: EditedField[],
  formMethods: UseFormReturn<Record<string, any>, any, undefined>
) => {
  const formattedEditDetails = editedFields.map((editedField) => {
    // If is a timefield, convert the time from local time to form submission time
    if (isDate(editedField.afterValue) && editedField.id !== "localDateTime") {
      const timezoneOffset =
        useFormRendererStore.getState().formSubmission?.timezoneOffset ?? "";
      const convertedTimeValue = zonedTimeToUtc(
        editedField.afterValue,
        timezoneOffset
      );
      return {
        id: editedField.id,
        value: convertedTimeValue.toISOString(),
      };
    }

    // checks if it's an image upload
    if (editedField.name === "Photo") {
      return {
        id: editedField.id,
        value: formMethods.getValues(editedField.id),
      };
    }

    // Changed Table components must have the type: table attached to the object
    if (
      Array.isArray(editedField.beforeValue) ||
      Array.isArray(editedField.afterValue)
    ) {
      return {
        id: editedField.id,
        type: "table",
        value: editedField.afterValue,
      };
    }

    return {
      id: editedField.id,
      value: editedField.afterValue,
    };
  });

  let localDateTime = undefined;
  const localDateTimeObject = formattedEditDetails?.find(
    (field) => field.id === "localDateTime"
  )?.value as unknown as Date;
  if (localDateTimeObject) {
    localDateTime =
      formatInTimeZone(
        localDateTimeObject,
        getFormattedTimezoneOffset(),
        "y-MM-dd'T'HH:mm:ss"
      ) +
      `.000${
        useFormRendererStore.getState().formSubmission?.timezoneOffset ??
        getFormattedTimezoneOffset()
      }`;
  }
  const fields = formattedEditDetails.filter(
    (field) => field.id !== "localDateTime"
  );
  return { fields, localDateTime };
};

/**
 * This method is called on a create form submission.
 * It's purpose is to add the type: 'table' to any field that needs it.
 * The 'type: table' needs to be added to the create form submission for the backend
 * to know how to update this field in the future
 * @param params
 * @returns
 */
export const formatTableTypes = (params: {
  queryFields: { id: string; value: any }[];
  formValues: Record<string, any>;
}) => {
  const tableFieldIdsArray = Object.entries(params.formValues)
    .filter(([_, value]) => Array.isArray(value))
    .map(([key, _]) => key);
  params.queryFields.map((field) => {
    const existingFieldIndex = tableFieldIdsArray.findIndex(
      (tableField) => tableField === field.id
    );
    if (existingFieldIndex !== -1) {
      tableFieldIdsArray.splice(existingFieldIndex, 1);
    }
  });

  return [
    ...params.queryFields,
    ...tableFieldIdsArray.map((id) => ({ id, value: [], type: "table" })),
  ];
};

export const renderFormComponent = (
  type: RowFieldType | undefined
): ElementType => {
  if (!type) return Null;
  switch (type) {
    case "Checkbox":
      return CheckboxField;
    case "TimeField":
      return TimeField;
    case "DateField":
      return DateField;
    case "SignatureField":
      return SignatureField;
    case "RadioField":
      return RadioField;
    case "AdderField":
      return AdderField;
    case "UserField":
      return UserField;
    case "MultiUserField":
      return MultiUserField;
    case "TotalField":
      return TotalField;
    case "BranchField":
      return BranchField;
    case "DivisionField":
      return DivisionField;
    case "PickerField":
      return PickerField;
    case "NumberField":
    case "TextArea":
    case "TextField":
      return Field;
    case "MultiImageField":
      return MultiImageField;
    case "MultiQuoteItemField":
      return MultiQuoteItemField;
    case "LocationField":
      return LocationField;
    default:
      return Null;
  }
};

export const renderEditFormComponent = (
  type: RowFieldType | undefined
): ElementType => {
  if (!type) return Null;
  switch (type) {
    case "Checkbox":
      return EditCheckboxField;
    case "TimeField":
      return EditTimeField;
    case "DateField":
      return EditDateField;
    case "AdderField":
      return EditAdderField;
    case "UserField":
      return EditUserField;
    case "MultiUserField":
      return EditMultiUserField;
    case "TotalField":
      return EditTotalField;
    case "BranchField":
      return EditBranchField;
    case "DivisionField":
      return EditDivisionField;
    case "PickerField":
      return EditPickerField;
    case "NumberField":
      return EditNumberField;
    case "TextArea":
      return EditTextAreaField;
    case "TextField":
      return EditField;
    case "MultiImageField":
      return EditMultiImageField;
    case "MultiQuoteItemField":
      return EditMultiQuoteItemField;
    case "LocationField":
      return EditLocationField;
    default:
      return Null;
  }
};
