import { get } from "@/utils/helpers/lodashCopies";
import { formatInTimeZone } from "date-fns-tz";
import { usaDateTimeFormat } from "@/utils/helpers/dateFormat";
import { Field } from "./FIeld";
import { FC } from "react";
import { EditDateField } from "./EditDateField";
import { RowField } from "@/types/forms/formEngine";
import { useFormRendererStoreSelectors } from "@/lib/zustand/formRendererStore";

type EditMetadataFieldProps = {
  fieldItem: RowField;
};
export const EditMetadataField: FC<EditMetadataFieldProps> = ({
  fieldItem,
}) => {
  const formSubmission = useFormRendererStoreSelectors.use.formSubmission();

  if (fieldItem.type === "WeekDateField") {
    return (
      <EditDateField
        key={`WeekDateField:${fieldItem.id}`}
        fieldItem={fieldItem}
      />
    );
  }

  if (fieldItem.type === "DateTimeField") {
    const timezone = get(formSubmission, "timezoneOffset");
    const metadataField = get(formSubmission, fieldItem.path ?? "");

    let value = "";

    if (timezone && metadataField) {
      value = formatInTimeZone(
        new Date(metadataField),
        timezone,
        usaDateTimeFormat
      );
    }

    return (
      <Field
        key={`DateTimeField:${value}`}
        fieldItem={fieldItem}
        value={value}
      />
    );
  }

  return <></>;
};
