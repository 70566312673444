import { LoadingSpinner } from "@/UI/Loading";
import { AdminBranchForm } from "@/components/Admin";
import { useBranch } from "@/lib/react-query/queryHooks/useBranch";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";

const AdminBranchPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const branchId = searchParams.get("branchId") ?? "";
  const { data: branch, isLoading: branchLoading } = useBranch({ branchId });

  return (
    <div className="w-full bg-transparent pt-14">
      <div className="h-screen items-center overflow-y-auto">
        <div>
          <Link
            className="my-4 flex w-fit items-center gap-x-1 text-theme-black-secondary"
            to="/admin"
          >
            <ArrowLeftIcon className="h-6 w-6" />
            <span className="text-xs font-medium">Back to Account</span>
          </Link>
          <h1 className="mb-12 text-4xl font-semibold">Branch Admin</h1>
        </div>
        {branchLoading && <LoadingSpinner />}
        <AdminBranchForm branchData={branch} edit={true} />
      </div>
    </div>
  );
};

export default AdminBranchPage;
