import { AdminUserDetailsFormSchema } from "@/components/Admin/AdminUserForm";

export const filterFormDefaultValues = {
  branch: { id: "", value: "" },
  firstName: { id: "", value: "" },
  email: { id: "", value: "" },
};

export const userDetailsFormDefaultValues = {
  account: { id: "", value: "" },
  authMethod: { id: "", value: "" },
  firstName: "",
  lastName: "",
  email: "",
  role: [],
  branch: [],
  veteran: false,
  assignPermission: false,
  permissions: [],
  occupations: [],
  phoneNumber: "",
};

export const userFormToEditQueryMapper = (
  data: AdminUserDetailsFormSchema,
  accountId: string | undefined
) => {
  const divisions = data.division?.map((division) => division.value) ?? [];
  const branches = data.branch.map((branch) => branch.value);
  const permissions = data.permissions.map((permission) => permission.value);
  const occupations = data.occupations
    ? data.occupations.map((occupation) => occupation.value)
    : [];
  return {
    email: data.email === "" ? undefined : data.email,
    phoneNumber: data.phoneNumber === "" ? undefined : data.phoneNumber,
    authMethod: data.authMethod.value as "web" | "mobile" | "both",
    metadata: {
      accountId,
      branchIds: branches,
      divisionIds: divisions,
      firstName: data.firstName,
      lastName: data.lastName,
      occupations,
      permissions,
      veteran: data.veteran,
    },
  };
};

export const userFormToCreateQueryMapper = (
  data: AdminUserDetailsFormSchema
) => {
  const divisions = data.division?.map((division) => division.value) ?? [];
  const branches = data.branch.map((branch) => branch.value);
  const occupations = data.occupations
    ? data.occupations.map((occupation) => occupation.value.toLowerCase())
    : [];
  const permissions = data.permissions.map((permission) => permission.value);
  return {
    email: data.email === "" ? undefined : data.email,
    phoneNumber: data.phoneNumber === "" ? undefined : data.phoneNumber,
    authMethod: data.authMethod.value as "web" | "mobile" | "both",
    metadata: {
      accountId: data.account?.id,
      branchIds: branches,
      divisionIds: divisions,
      firstName: data.firstName,
      lastName: data.lastName,
      occupations,
      permissions,
      veteran: data.veteran,
    },
  };
};
