import { Option } from "@/types/option";
import { ColumnDef, Row } from "@tanstack/react-table";
import { GqlPriceBookItem } from "@/lib/graphql/graphql";
import { CircledXIcon } from "@/assets";

interface PricebookColumnsProps {
  branches?: Option[];
  divisions?: Option[];
  handleDeletePricebookItem: (id: string, name: string) => void;
}

const selectorFilterFn = (
  row: Row<GqlPriceBookItem>,
  _id: string,
  value: string,
  type: "branch" | "division" | "name" | "description"
) => {
  if (!value) return true;

  const {
    original: { branchIds, divisionIds, name, description },
  } = row;

  let match: string | undefined;

  switch (type) {
    case "branch":
      match = branchIds?.find((branch) => branch === value);
      break;
    case "division":
      match = divisionIds?.find((division) => division === value);
      break;
    case "name":
      match = name?.toLowerCase().includes(value.toLowerCase()) ? "found" : "";
      break;
    case "description":
      match = description?.toLowerCase().includes(value.toLowerCase())
        ? "found"
        : "";
      break;
    default:
      break;
  }

  if (match) return true;

  return false;
};

export const getPricebookColumns = ({
  branches = [],
  divisions = [],
  handleDeletePricebookItem,
}: PricebookColumnsProps): ColumnDef<GqlPriceBookItem>[] => {
  return [
    {
      header: "",
      accessorKey: "actions",
      enableSorting: false,
      cell: ({
        row: {
          original: { id, name },
        },
      }) => {
        return (
          <button
            className="flex items-center justify-center rounded-full bg-theme-red-primary opacity-0 group-hover:opacity-100"
            onClick={() => handleDeletePricebookItem(id, name)}
          >
            <CircledXIcon />
          </button>
        );
      },
    },
    {
      header: "Name",
      accessorKey: "name",
      cell: ({
        row: {
          original: { name },
        },
      }) => {
        return (
          <div className="flex items-center gap-x-4">
            <span>{name}</span>
          </div>
        );
      },
      enableSorting: true,
      filterFn: (row, id, value) => selectorFilterFn(row, id, value, "name"),
      sortingFn: (rowA, rowB) =>
        rowA.original.name.localeCompare(rowB.original.name),
    },
    {
      header: "Description",
      accessorKey: "description",
      enableSorting: true,
      filterFn: (row, id, value) =>
        selectorFilterFn(row, id, value, "description"),
      sortingFn: (rowA, rowB) => {
        if (!rowA.original.description && !rowB.original.description) return 0;
        if (!rowA.original.description) return 1;
        if (!rowB.original.description) return -1;
        return rowA.original?.description?.localeCompare(
          rowB.original?.description
        );
      },
    },
    {
      header: "Division",
      accessorKey: "division",
      cell: ({
        row: {
          original: { divisionIds: pricebookDivisionIds },
        },
      }) => {
        const pricebookDivisionsSet = new Set(pricebookDivisionIds);

        const accountFilterdDivisions = divisions.filter((division) =>
          pricebookDivisionsSet.has(division.id)
        );

        const divisionNames = accountFilterdDivisions.map(
          (division) => division.value
        );

        return (
          <p className="line-clamp-2 max-w-[200px] whitespace-normal text-start">
            {divisionNames.join(", ")}
          </p>
        );
      },
      filterFn: (row, id, value) =>
        selectorFilterFn(row, id, value, "division"),
      enableSorting: false,
    },
    {
      header: "Branch",
      accessorKey: "branch",
      cell: ({
        row: {
          original: { branchIds: pricebookBranchIds },
        },
      }) => {
        const pricebookBranchesSet = new Set(pricebookBranchIds);

        const accountFilterdBranches = branches.filter((branch) =>
          pricebookBranchesSet.has(branch.id)
        );

        const branchNames = accountFilterdBranches.map(
          (branch) => branch.value
        );

        return (
          <p className="line-clamp-2 max-w-[200px] whitespace-normal text-start">
            {branchNames.join(", ")}
          </p>
        );
      },
      filterFn: (row, id, value) => selectorFilterFn(row, id, value, "branch"),
      enableSorting: false,
    },
    {
      header: "Category",
      accessorKey: "category",
    },
    {
      header: "Rate",
      accessorKey: "rate",
      cell: ({
        row: {
          original: { rate, unit },
        },
      }) => {
        return <span>{`$${rate}/${unit}`}</span>;
      },
    },
  ];
};
