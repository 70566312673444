import { BackArrow } from "@/assets";
import { classNames } from "@/utils/helpers/classNames";
import { handleNavigation } from "@/utils/helpers/handleNavigation";
import { useLocation, useNavigate } from "react-router-dom";

const SettingsExpandedContent = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <button
        className={classNames(
          "flex h-10 w-[149px] items-center justify-start gap-x-2 rounded-md pl-3"
        )}
        onClick={(event) => {
          handleNavigation({
            event,
            href: "settings",
            navigate,
            pathname,
          });
        }}
      >
        <BackArrow />

        <span className="text-xs font-medium text-palette-secondaryBlack">
          Settings
        </span>
      </button>

      <button
        onClick={() => navigate("/settings/form-manager")}
        className={classNames(
          "flex h-10 w-[149px] items-center justify-start rounded-md pl-3",
          pathname === "/settings/form-manager"
            ? "bg-palette-secondaryGreen"
            : ""
        )}
      >
        <span
          className={classNames(
            "text-sm font-medium",
            pathname === "/settings/form-manager" ? "text-palette-green" : ""
          )}
        >
          Form Manager
        </span>
      </button>
      <button
        onClick={() => navigate("/settings/pricebook")}
        className={classNames(
          "flex h-10 w-[149px] items-center justify-start rounded-md pl-3",
          pathname === "/settings/pricebook" ? "bg-palette-secondaryGreen" : ""
        )}
      >
        <span
          className={classNames(
            "text-sm font-medium",
            pathname === "/settings/pricebook" ? "text-palette-green" : ""
          )}
        >
          Pricebook
        </span>
      </button>
    </>
  );
};

export { SettingsExpandedContent };
