import { FC } from "react";

interface EditIconProps {
  fill?: string;
}
const EditIcon: FC<EditIconProps> = ({ fill = "#3CD38B" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M17.8877 6.55546L18.2688 6.93651C18.5648 7.23252 18.5648 7.71119 18.2688 8.00406L17.3524 8.92361L15.9006 7.47186L16.817 6.55546C17.113 6.25944 17.5917 6.25944 17.8846 6.55546H17.8877ZM10.6069 12.7687L14.833 8.53942L16.2848 9.99117L12.0555 14.2173C11.9642 14.3086 11.8508 14.3748 11.728 14.4094L9.88575 14.9353L10.4117 13.0931C10.4463 12.9703 10.5124 12.8569 10.6038 12.7656L10.6069 12.7687ZM15.7494 5.4879L9.53619 11.698C9.26222 11.972 9.06382 12.3089 8.9599 12.6774L8.05924 15.8265C7.98366 16.0911 8.05609 16.3745 8.25134 16.5697C8.44659 16.765 8.73001 16.8374 8.99454 16.7618L12.1437 15.8612C12.5153 15.7541 12.8522 15.5557 13.1231 15.2849L19.3363 9.07477C20.2212 8.18986 20.2212 6.75385 19.3363 5.86895L18.9553 5.4879C18.0704 4.60299 16.6344 4.60299 15.7494 5.4879ZM6.77124 6.71607C5.24076 6.71607 4 7.95683 4 9.48731V18.053C4 19.5835 5.24076 20.8242 6.77124 20.8242H15.3369C16.8674 20.8242 18.1082 19.5835 18.1082 18.053V14.5259C18.1082 14.1071 17.7712 13.7701 17.3524 13.7701C16.9335 13.7701 16.5966 14.1071 16.5966 14.5259V18.053C16.5966 18.7489 16.0329 19.3126 15.3369 19.3126H6.77124C6.07528 19.3126 5.51159 18.7489 5.51159 18.053V9.48731C5.51159 8.79135 6.07528 8.22765 6.77124 8.22765H10.2983C10.7171 8.22765 11.0541 7.89069 11.0541 7.47186C11.0541 7.05302 10.7171 6.71607 10.2983 6.71607H6.77124Z"
        fill={fill}
      />
    </svg>
  );
};

export { EditIcon };
