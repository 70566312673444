import "./styles/rp-styles.css";
import "./styles/jb-styles.css";

import { FC, useRef } from "react";
import { FieldError } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import { LeftArrow, RightArrow } from "../../assets";
import {
  endOfMonth,
  endOfWeek,
  getWeek,
  setWeek,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
} from "date-fns";
import { classNames } from "@/utils/helpers/classNames";

type RangePickerProps = {
  className?: string;
  classNameExt?: string;
  dateFormat?: string | string[];
  disabled?: boolean;
  endDate: Date | null;
  error?: FieldError | undefined;
  label?: string;
  maxDate?: Date;
  minDate?: Date;
  onCalendarClose?: () => void;
  onChange: (_date: [Date | null, Date | null]) => void;
  onDateRange: (_values: [Date | null, Date | null]) => void;
  placeholderText?: string;
  showFilters?: boolean;
  startDate: Date | null;
};

const RangePicker: FC<RangePickerProps> = ({
  className = "",
  classNameExt = "rp",
  dateFormat = undefined,
  disabled = false,
  endDate,
  error,
  label,
  maxDate,
  minDate,
  onCalendarClose,
  onChange,
  onDateRange,
  placeholderText = "MM/DD/YY - MM/DD/YY",
  showFilters = true,
  startDate,
}) => {
  const handleChange = (date: [Date | null, Date | null]) => {
    onChange(date);
  };

  const liRef = useRef<HTMLDListElement>();

  const chageButtonBg = (button: HTMLButtonElement) => {
    if (liRef.current) {
      liRef.current.classList.remove("bg-theme-green-primary");
    }

    liRef.current = button.parentNode as HTMLDListElement;
    liRef.current.classList.add("bg-theme-green-primary");
  };

  const onClickOutside = () => {
    if (startDate && !endDate) {
      onChange([startDate, startDate]);
    }
  };

  return (
    <div className={classNames("flex flex-col", className)}>
      {label && (
        <label className="text-sm font-semibold text-theme-black-secondary">
          {label}
        </label>
      )}
      <ReactDatePicker
        dateFormat={dateFormat}
        selectsRange
        startDate={startDate}
        endDate={endDate}
        onChange={handleChange}
        className={`${error && "border-theme-red-primary"}`}
        placeholderText={placeholderText}
        disabled={disabled}
        maxDate={maxDate}
        minDate={minDate}
        onCalendarClose={onCalendarClose}
        allowSameDay
        showPopperArrow={false}
        wrapperClassName={classNameExt}
        popperClassName={classNameExt}
        calendarClassName={classNameExt}
        onClickOutside={onClickOutside}
        renderDayContents={(day) => (
          <div className="flex h-full w-full items-center justify-center">
            <span className="relative text-sm font-medium">{day}</span>
          </div>
        )}
        renderCustomHeader={({
          decreaseMonth,
          increaseMonth,
          monthDate,
          nextMonthButtonDisabled,
          prevMonthButtonDisabled,
        }) => {
          return (
            <div className="m-3 flex justify-center">
              <div className="flex w-44 justify-between">
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  <LeftArrow />
                </button>

                <div
                  data-testid="datepicker-toggle-text"
                  className="flex gap-1"
                >
                  <span className="text-sm font-semibold">
                    {monthDate.toDateString().substring(4, 7)}
                  </span>
                  <span className="text-sm font-semibold">
                    {monthDate.toDateString().substring(11, 15)}
                  </span>
                </div>

                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  <RightArrow />
                </button>
              </div>
            </div>
          );
        }}
      >
        {showFilters ? (
          <div className="flex h-full flex-col items-center justify-center">
            <ul className="flex w-full flex-col gap-y-4 font-semibold">
              <li className="flex h-7 items-center justify-center">
                <button
                  onClick={(event) => {
                    onDateRange([new Date(), new Date()]);
                    chageButtonBg(event.target as HTMLButtonElement);
                  }}
                >
                  Today
                </button>
              </li>
              <li className="flex h-7 items-center justify-center">
                <button
                  onClick={(event) => {
                    onDateRange([
                      subDays(new Date(), 1),
                      subDays(new Date(), 1),
                    ]);
                    chageButtonBg(event.target as HTMLButtonElement);
                  }}
                >
                  Yesterday
                </button>
              </li>
              <li className="flex h-7 items-center justify-center">
                <button
                  onClick={(event) => {
                    onDateRange([
                      startOfWeek(new Date()),
                      endOfWeek(new Date()),
                    ]);
                    chageButtonBg(event.target as HTMLButtonElement);
                  }}
                >
                  This Week
                </button>
              </li>
              <li className="flex h-7 items-center justify-center">
                <button
                  onClick={(event) => {
                    const currentWeek = getWeek(new Date());
                    const lastWeek = setWeek(new Date(), currentWeek - 1);
                    const startOfLastWeek = startOfWeek(lastWeek);
                    const endOfLastWeek = endOfWeek(lastWeek);

                    onDateRange([startOfLastWeek, endOfLastWeek]);
                    chageButtonBg(event.target as HTMLButtonElement);
                  }}
                >
                  Last Week
                </button>
              </li>
              <li className="flex h-7 items-center justify-center">
                <button
                  onClick={(event) => {
                    const firsDayOfCurrentMonth = startOfMonth(new Date());
                    const lastDayOfCurrentMonth = endOfMonth(new Date());

                    onDateRange([firsDayOfCurrentMonth, lastDayOfCurrentMonth]);
                    chageButtonBg(event.target as HTMLButtonElement);
                  }}
                >
                  This Month
                </button>
              </li>
              <li className="flex h-7 items-center justify-center">
                <button
                  onClick={(event) => {
                    const lastMonth = subMonths(new Date(), 1);
                    const firstDayOfLastMonth = startOfMonth(lastMonth);
                    const lastDayOfLastMonth = endOfMonth(lastMonth);

                    onDateRange([firstDayOfLastMonth, lastDayOfLastMonth]);
                    chageButtonBg(event.target as HTMLButtonElement);
                  }}
                >
                  Last Month
                </button>
              </li>
            </ul>
          </div>
        ) : null}
      </ReactDatePicker>

      {error && (
        <p className="text-[12px] font-medium text-theme-red-primary">
          {error.message || "Error"}
        </p>
      )}
    </div>
  );
};

export { RangePicker };
