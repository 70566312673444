import { classNames } from "@/utils/helpers/classNames";
import { FC, HTMLInputTypeAttribute } from "react";
import { RowFieldProps } from "./FIeld";
import { useFormContext } from "react-hook-form";
import { ControlledInput } from "@/UI/Input";
import { RowField } from "@/types/forms/formEngine";
import {
  evaluateExpression,
  transformTitleByFieldItem,
} from "@/utils/helpers/forms/formRendererHelpers";

type RowEditFieldProps = RowFieldProps & {
  inputType?: HTMLInputTypeAttribute | undefined;
  fieldItem: RowField;
};
export const EditField: FC<RowEditFieldProps> = ({
  inputType = "text",
  fieldItem,
}) => {
  const { control } = useFormContext();
  const title = transformTitleByFieldItem(fieldItem);
  const hidden = evaluateExpression(fieldItem?.attributes?.hiddenIf);
  return (
    <div
      className={`space-y-1 ${hidden ? "hidden" : ""}`}
      style={{
        gridColumnStart: fieldItem?.columnStart,
        gridColumnEnd: fieldItem?.columnEnd,
      }}
    >
      {title && (
        <h4
          className={classNames(
            "text-sm font-medium text-theme-black-secondary"
          )}
        >
          {title}
        </h4>
      )}

      <div className="relative mr-4 text-base">
        <div
          className={classNames(
            "w-full",
            fieldItem?.attributes?.endText && "flex"
          )}
        >
          <ControlledInput
            control={control}
            dataTestId={title}
            name={`${fieldItem?.id}`}
            type={inputType}
            inputClasses="[&::-webkit-slider-thumb]:bg-pink-500"
          />
          {fieldItem?.attributes?.endText && (
            <span className="pointer-events-none my-auto pl-1">
              {fieldItem?.attributes?.endText}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
