import { usePricebookItems } from "@/lib/react-query/queryHooks/usePricebookItems";
import * as React from "react";
import { useState } from "react";
import { useBranches } from "@/lib/react-query/queryHooks/useBranches";
import { useDivisions } from "@/lib/react-query/queryHooks/useDivisions";
import { Button } from "@/UI/Button";
import { Table } from "@/components/Table";
import { getPricebookColumns } from "@/utils/columnDefinitions/pricebookColumns";
import { GqlPriceBookItem } from "@/lib/graphql/graphql";
import { DangerModal } from "@/UI/Modal";
import { useDeletePricebookItem } from "@/lib/react-query/mutationHooks/useDeletePricebookItem";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "@/lib/react-query/constants";
import { PricebookFilters } from "@/components/Pricebook/PricebookFilters";
import { PricebookItemModal } from "@/components/Pricebook/PricebookItemModal";

export const AdminPricebookPage: React.FC = () => {
  const [pricebookItemModalOpen, setPricebookItemModalOpen] = useState(false);
  const [filters, setFilters] = useState<{ id: string; value: string }[]>([]);
  const { data: branches, isLoading: branchesLoading } = useBranches({
    options: { staleTime: 5 * 1000 * 60 },
  });
  const { data: divisions, isLoading: divisionsLoading } = useDivisions({
    options: { staleTime: 5 * 1000 * 60 },
  });

  const branchOptions = branches?.data.map((item) => ({
    id: item.id,
    value: item.name,
  }));

  const divisionOptions = divisions?.data.map((item, idx) => ({
    id: item.id ?? idx.toString(),
    value: item.divisionName ?? "",
  }));
  const onAddPricebookItem = () => {
    setPricebookItemModalOpen(true);
  };

  const { data: pricebookItems, isLoading: pricebookItemsLoading } =
    usePricebookItems({ options: { staleTime: 5 * 1000 * 60 } });

  const loadingState =
    branchesLoading || divisionsLoading || pricebookItemsLoading;

  const [deletePricebookItemModal, setDeletePricebookItemModal] = useState<{
    open: boolean;
    pricebookItemId?: string;
    name?: string;
  }>({
    open: false,
  });
  const handleDeletePricebookItem = (id: string, name: string) => {
    setDeletePricebookItemModal({ open: true, pricebookItemId: id, name });
  };
  const deletePricebookItem = useDeletePricebookItem();
  const queryClient = useQueryClient();
  const handleDeletePricebookItemConfirm = async () => {
    if (!deletePricebookItemModal.pricebookItemId) return;
    try {
      await deletePricebookItem.mutate({
        deletePriceBookItemId: deletePricebookItemModal.pricebookItemId,
      });
      setDeletePricebookItemModal({ open: false });
      toast.success("Pricebook item deleted");
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PRICEBOOK_ITEMS] });
    } catch (error) {
      toast.error("Failed to delete Pricebook Item");
    }
  };

  return (
    <div className="h-screen w-full pt-14">
      <header className="mb-8 flex w-full items-center justify-between">
        <h1 className="text-4xl font-semibold">Pricebook Items</h1>
        <div className="flex gap-x-7">
          <PricebookFilters
            branchOptions={branchOptions}
            divisionOptions={divisionOptions}
            filters={filters}
            setFilters={setFilters}
          />
          <Button
            className="!rounded-full !text-white"
            label="Add Item"
            onClick={onAddPricebookItem}
            variant="primary"
          />
        </div>
      </header>

      <div className="h-[calc(100%-72px)] w-full overflow-auto rounded-md border bg-white">
        <Table
          clickableRow
          emptyMessage="No pricebook items found. Please check your search and filters and try again."
          filters={filters}
          loading={loadingState}
          tableColumns={getPricebookColumns({
            branches: branchOptions || [],
            divisions: divisionOptions || [],
            handleDeletePricebookItem,
          })}
          tableData={
            (pricebookItems?.data.priceBookItems as GqlPriceBookItem[]) || []
          }
          thClasses="!uppercase !tracking-[.52] !font-medium !text-[13px] !text-palette-black"
          theadClasses="!bg-[#fafafa]"
          trClasses="hover:bg-[#e9f2f4] group"
        />
      </div>
      <PricebookItemModal
        open={pricebookItemModalOpen}
        setOpen={setPricebookItemModalOpen}
      />

      <DangerModal
        cancelAction={() =>
          setDeletePricebookItemModal({
            open: false,
            name: deletePricebookItemModal.name,
          })
        }
        cancelButtonDisabled={false}
        confirmAction={handleDeletePricebookItemConfirm}
        confirmButtonDisabled={false}
        message="Are you sure you want to delete this pricebook item? This action cannot be undone."
        open={deletePricebookItemModal.open}
        title={`Delete Item '${deletePricebookItemModal.name}'`}
      />
    </div>
  );
};
